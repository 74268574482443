import { useEffect, useState } from 'react';
import Style from './Style.module.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import Chat from '@material-ui/icons/Chat';
import Email from '@material-ui/icons/Email';
import { homePage, api } from '../../../Config';

function DuoVerification(props) {
    const [message, setMessage] = useState('Selecciona uno de los siguientes métodos');
    const [msgType, setMsgType] = useState('default');
    const [method, setMethod] = useState('');
    const [passcode, setPasscode] = useState('');
    const [verifying, setVerifying] = useState(false);

    useEffect(() => verify('push'), []);

    const handleChange = event => {
        setPasscode(event.target.value);
    };

    const verify = method => {
        setVerifying(true)
        setMethod(method)
        //console.log('passcode',passcode )
        fetch(api.urlDuoVerification, { 
            method: 'post', 
            headers: new Headers({'Content-Type': 'application/json'}), 
            body: JSON.stringify({
                'username': props.username,
                'password': props.password,
                'method': method,
                'passcode': passcode
            })
        })
        .then((data) => {
            if (data.status === 200){
                setMessage('Te hemos enviado un código de verificación')
                setMsgType('info')
            } else if (data.status === 202) {
                data.json().then((data) => {
                    localStorage.setItem("accessToken", data.access);
                    localStorage.setItem("refreshToken", data.refresh);
                    localStorage.setItem("userUuid", data.user_uuid);
                    localStorage.setItem("username", data.username);
                    localStorage.setItem("email", data.email);
                    localStorage.setItem("firstName", data.first_name);
                    localStorage.setItem("lastName", data.last_name);
                    localStorage.setItem("department", data.department);
                    ////////////////// localStorage.setItem("permissions", data.permissions);
                    ////////////////// localStorage.setItem("groups", data.groups);
                    window.location.href = homePage;
                })
            } else if (data.status === 401) {
                setMessage('No se ha podido verificar tu identidad')
                setMsgType('error')
            } else {
                setMessage('Se ha producido un error')
                setMsgType('error')
            }
            setVerifying(false)
        })
        //.catch(console.log)
    };
    
    return (
        <>
            <span className={Style.titulo}>Confirma tu identidad</span>
            <div className={msgType === 'default' ? Style.defaultMsg : msgType === 'error' ? Style.errorMsg : msgType === 'warning' ? Style.warningMsg : msgType === 'info' ? Style.infoMsg : msgType === 'success' ? Style.successMsg : ''}>
                {message}
            </div>
            <br /><br />
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <div onClick={() => verify('push')} className={Style.option+' '+(verifying && method === 'push' ? Style.verifying : '')}>
                        <PhoneIphone/>
                        <div>PUSH</div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div onClick={() => verify('sms')} className={Style.option+' '+(verifying && method === 'sms' ? Style.verifying : '')}>
                        <Chat/>
                        <div>SMS</div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div onClick={() => verify('email')} className={Style.option+' '+(verifying && method === 'email' ? Style.verifying : '')}>
                        <Email/>
                        <div>EMAIL</div>
                    </div>
                </Grid>
            </Grid>
            <br />
            <TextField label="C&oacute;digo" onChange={handleChange} inputProps={{maxLength:10}}/>
            <br/><br/><br/>
            <Button onClick={() => verify('passcode')} disabled={passcode === '' ? Boolean(true) : Boolean(false)} className={Style.btnVerify+' '+Style.fullWidth+' '+(verifying && method === 'passcode' ? Style.verifying : '')} variant="outlined" color="secondary" size="large">
                Verificar C&oacute;digo
            </Button>
            <br/><br/>
        </>
    );
}

export default DuoVerification;