// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../DesignConstants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_actions__2Vlnt{\n    padding: 16px 24px !important;\n}\n\n.Style_btnHelp__1XC48{\n    float: right;\n    margin-top: 6px !important;\n    cursor: pointer;\n    color: var(--secondaryColor);\n    transition: all 0.3s !important;\n}\n\n.Style_btnHelp__1XC48:hover{\n    color: var(--primaryColor);\n}\n\n.Style_processing__1n8pf{\n    pointer-events: none;\n    animation-name: Style_processing__1n8pf;\n    animation-duration: 1.5s;\n    animation-iteration-count: infinite;\n}\n\n@keyframes Style_processing__1n8pf {\n    0% {\n        opacity: 1;\n    }\n    50% {\n        opacity: 0.4;\n    }\n    100% {\n        opacity: 1;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/reusables/modal/Style.module.css"],"names":[],"mappings":"AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,0BAA0B;IAC1B,eAAe;IACf,4BAA4B;IAC5B,+BAA+B;AACnC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,oBAAoB;IACpB,uCAA0B;IAC1B,wBAAwB;IACxB,mCAAmC;AACvC;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,YAAY;IAChB;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":["@import '../../../DesignConstants.css';\n\n.actions{\n    padding: 16px 24px !important;\n}\n\n.btnHelp{\n    float: right;\n    margin-top: 6px !important;\n    cursor: pointer;\n    color: var(--secondaryColor);\n    transition: all 0.3s !important;\n}\n\n.btnHelp:hover{\n    color: var(--primaryColor);\n}\n\n.processing{\n    pointer-events: none;\n    animation-name: processing;\n    animation-duration: 1.5s;\n    animation-iteration-count: infinite;\n}\n\n@keyframes processing {\n    0% {\n        opacity: 1;\n    }\n    50% {\n        opacity: 0.4;\n    }\n    100% {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "Style_actions__2Vlnt",
	"btnHelp": "Style_btnHelp__1XC48",
	"processing": "Style_processing__1n8pf"
};
export default ___CSS_LOADER_EXPORT___;
