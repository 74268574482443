import { api } from './Config';
import * as msg from './components/reusables/toast/Toast';

export default function RefreshToken() {
  fetch(api.urlRefreshToken, { 
    method: 'post', 
    headers: new Headers({'Content-Type': 'application/json'}), 
    body: JSON.stringify({
      'refresh': localStorage.getItem("refreshToken")
    })
  })
  .then((data) => {
    if (data.status === 200){
      data.json().then((data) => {
        localStorage.setItem("accessToken", data.access);
      })
    } else {
      msg.warning('Tu sesión ha expirado')
      window.location.href = 'login';
    }
  })
};
