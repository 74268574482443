import { api } from '../../../Config';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Modal from '../../reusables/modal/Modal';

import { Container, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import CSRFToken from '../../reusables/CSRFToken/CSRFToken';

function Register(props) {
    const [processing, setProcessing] = useState(false);
    const [industries, setIndustries] = useState([]);
    const [form, setForm] = useState({
        name: '',
        acronym: '',
        direction: '',
        nit: '',

        business_name: '',
        tradename: '',
        email: '',
        phone: '',

        industry: '',
    });

    useEffect(() => {

        getIndustries();

        if (props.element !== '') {
            setForm({
                name: props.element.name,
                acronym: props.element.acronym,
                direction: props.element.direction,
                nit: props.element.nit,

                business_name: props.element.business_name,
                tradename: props.element.tradename,
                email: props.element.email,
                phone: props.element.phone,

                industry: props.element.industry ? props.element.industry.id : ''
            })
        }
        
    }, []);


    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }

    function getIndustries() {
        setProcessing(true)
        fetch(api.urlIndustry, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        setIndustries(data)
                        setProcessing(false)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getIndustries()
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            //.catch(console.log)
    }

    function register(){
        setProcessing(true)
        const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value;
        fetch(api.urlClients+(props.element !== '' ? props.element.id+'/' : ''), {
            method: (props.element !== '' ? 'put' : 'post'),
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`, 'Content-Type': 'application/json'
            ,'X-CSRFToken': csrftoken_pag,},
            credentials: 'include',
            body: JSON.stringify(form)
        })
        .then((data) => {

            if (data.status === 201 || data.status === 200){
                props.getElements()
                props.hideModal()
                setProcessing(false)
                msg.success(props.element !== '' ? 'Actualización exitosa' : 'Registro exitoso')
            } else if (data.status === 401) {
                RefreshToken()
                register()
            } else {
                data.json().then((data) => {
                    if (data.acronym){
                        msg.error(data.acronym)
                    }
                    if (data.email){
                        msg.error(data.email)
                    } /*else {
                        msg.error('Se ha producido un error')
                    }*/
                })
                setProcessing(false)
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    return (
        <Modal
            title={props.element === '' ? 'Registro' : 'Actualización'}
            secondaryActionName="Cancelar"
            primaryActionName={props.element === '' ? 'Registrar' : 'Actualizar'}
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={form.name === '' || form.acronym === '' || form.direction === '' /*|| form.nit === ''*/ || form.email === '' || form.phone === ''}
            processing={processing}
            maxWidth="sm">

            <CSRFToken />
            <TextField value={form.acronym} required onChange={handleChange} label="Acr&oacute;nimo" name="acronym" variant="outlined" size="small" inputProps={{maxLength:100}}/>
            <br/><br/>
            <TextField value={form.name} required onChange={handleChange} label="Nombre" name="name" variant="outlined" size="small" inputProps={{maxLength:100}} autoFocus={true}/>
            <br/><br/>
            <TextField value={form.direction} required onChange={handleChange} label="Direcci&oacute;n" name="direction" variant="outlined" size="small" inputProps={{maxLength:100}}/>
            <br/><br/>

            <TextField value={form.business_name} onChange={handleChange} label="Razón social" name="business_name" variant="outlined" size="small" inputProps={{maxLength:170}}/>
            <br/><br/>
            <TextField value={form.tradename} onChange={handleChange} label="Nombre comercial" name="tradename" variant="outlined" size="small" inputProps={{maxLength:170}}/>
            <br/><br/>

            <TextField value={form.email} required onChange={handleChange} label="Correo" name="email" type="email" variant="outlined" size="small" inputProps={{maxLength:100}}/>
            <br/><br/>
            <TextField value={form.phone} required onChange={handleChange} label="Teléfono" name="phone" type="number" variant="outlined" size="small" inputProps={{maxLength:20}}/>
            <br/><br/>

            <TextField value={form.nit} onChange={handleChange} label="NIT" name="nit" variant="outlined" size="small" inputProps={{maxLength:100}}/>
            <br/><br/>

            <Container maxWidth="lg">
                <FormControl variant="outlined" size="small">
                    <InputLabel>Industria</InputLabel>
                    <Select value={form.industry} name="industry" onChange={handleChange} label="Industria">
                        
                        <MenuItem value="">
                            <em>ninguno</em>
                        </MenuItem>

                        {industries.map((element, index) => (
                            <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Container>

        </Modal>
    );
}


export default Register;