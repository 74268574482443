import { api } from '../../../Config';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import * as msg from '../toast/Toast';
import { Zoom } from '@material-ui/core';
import Style from './Style.module.css';

function HtmlRenderer(props) {
    const [uuid] = useState(useParams().uuid);
    const [action] = useState(useParams().action);
    const [html, setHtml] = useState('');
    const [activeCampaign, setActiveCampaign] = useState(true);

    useEffect(() => getHtml(), []);

    function getHtml(){
        fetch(api.urlHtmlRenderer+uuid+'/'+props.type+'/'+action)
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setHtml(data.page)
                })
            } else if (data.status === 404) {
                setActiveCampaign(false)
            } else {
                msg.error('Se ha producido un error')
            }
        })
        .catch(console.log)
    }

    return (
        activeCampaign ?
            parse(html)
        :
            <Zoom in={true}>
                <div className={Style.container}>
                    <div>
                        <br/>
                        <img src="../../logo.png" alt="Sistemas Aplicativos, S.A." />
                        <br/><br/>
                        <div className={Style.paper}>
                            <br/>
                            <span className={Style.title}>Campa&ntilde;a vencida</span>
                            <br/><br/>
                            <div className={Style.detail}>Esta campa&ntilde;a ya no est&aacute; disponible para ti. Notifica al administrador de tu empresa.</div>
                            <br/>
                            <div className={Style.detail}>Agradecemos mucho tu comprensi&oacute;n.</div>
                            <br/>
                        </div>
                        <br/>
                        <div className={Style.copyright}>
                            All rights reserved. ® Copyright {new Date().getFullYear()}.
                            <br/>
                            Sistemas Aplicativos, S.A.
                        </div>
                        <br/>
                    </div>
                </div>
            </Zoom>
    );
}

export default HtmlRenderer;