// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_root__3lA8N{\n    background: #f6f6f6;\n    height: calc(100vh);\n    display: flex;\n    align-items: center;\n    padding: 0 17px;\n}\n\n.Style_content__uZBkC{\n    text-align: center;\n}\n\n.Style_item__1Q_yr{\n    box-shadow: 0px 0px 10px #ccc6 !important;\n    transition: 0.3s !important;\n    cursor: pointer;\n}\n\n.Style_item__1Q_yr:hover{\n    opacity: 1 !important;\n    box-shadow: 0px 2px 40px #ccc6 !important;\n}\n\n.Style_item__1Q_yr>div{\n    padding: 40px 10px !important;\n}", "",{"version":3,"sources":["webpack://src/components/homePage/Style.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yCAAyC;IACzC,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".root{\n    background: #f6f6f6;\n    height: calc(100vh);\n    display: flex;\n    align-items: center;\n    padding: 0 17px;\n}\n\n.content{\n    text-align: center;\n}\n\n.item{\n    box-shadow: 0px 0px 10px #ccc6 !important;\n    transition: 0.3s !important;\n    cursor: pointer;\n}\n\n.item:hover{\n    opacity: 1 !important;\n    box-shadow: 0px 2px 40px #ccc6 !important;\n}\n\n.item>div{\n    padding: 40px 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Style_root__3lA8N",
	"content": "Style_content__uZBkC",
	"item": "Style_item__1Q_yr"
};
export default ___CSS_LOADER_EXPORT___;
