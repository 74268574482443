import { api } from '../../../Config';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Modal from '../../reusables/modal/Modal';

import CSRFToken from '../../reusables/CSRFToken/CSRFToken';

function RegisterActivation(props) {
    const [processing, setProcessing] = useState(false);
    const [form, setForm] = useState({
        code: ''
    });

    useEffect(() => []);

    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }

    function register(){
        setProcessing(true)
        const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value;
        fetch(api.urlActivateCampaign, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken_pag
            },
            credentials: 'include',
            body: JSON.stringify({
                'code': form.code,
                'campaign': props.campaign.id
            })
        })
        .then((data) => {
            if (data.status === 200){
                if(props.typeCampaign === 'Programada'){
                    props.getCampaigns(props.typeCampaign)
                }
                props.hideModal()
                setProcessing(false)
                msg.success('La campaña ha sido actualizada con éxito')
            } else if (data.status === 401) {
                RefreshToken()
                register()
            } else {

                setProcessing(false)
                msg.error('Se ha producido un error')
                data.json().then((data) => {
                    msg.error(data.detail)
                })
                
            }
        })
        //.catch(console.log)
    }

    return (
        <Modal
            title={'Activación: ' + props.campaign.name}
            secondaryActionName="Cancelar"
            primaryActionName='Aceptar'
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={form.code === ''}
            processing={processing}>

            <CSRFToken />
            <TextField value={form.code} onChange={handleChange} label="Código" name="code" variant="outlined" size="small" inputProps={{maxLength:6}} autoFocus={true}/>
            <br/><br/>
        </Modal>
    );
}

export default RegisterActivation;