import Style from './Style.module.css';
import Divider from '@material-ui/core/Divider';
import BackButton from '../backButton/BackButton';

function PageTitle(props) {
    return (
        <>
            <div className={Style.container}>
                {props.backUrl ? 
                    <BackButton backUrl={props.backUrl} variant="title"/>
                : <></>}
                <span className={Style.primary}>{props.primary}</span>
                <br/>
                <span className={Style.secondary}>{props.secondary}</span>
            </div>
            <Divider/>
        </>
    );
}

export default PageTitle;