import Style from './Style.module.css';
import {Add} from '@material-ui/icons';
import {Grid, Card, CardContent, Container} from '@material-ui/core';
import Authenticated from '../reusables/authenticated/Authenticated';
import { Link } from "react-router-dom";

function HomePage() {
    return (
        <Authenticated>
            <div className={Style.root}>
                <Container maxWidth="lg" className={Style.content}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Link to="campaign-management">
                                <Card className={Style.item}>
                                    <CardContent>
                                        <Add fontSize="large"/>
                                        <br/><br/>
                                        Pruebas de filtrado de correo
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Link to="phishing-campaign-execution">
                                <Card className={Style.item}>
                                    <CardContent>
                                        <Add fontSize="large"/>
                                        <br/><br/>
                                        Campa&ntilde;as de phishing
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Authenticated>
    );
}

export default HomePage;