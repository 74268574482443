import { api } from '../../Config';
import * as msg from '../reusables/toast/Toast';
import Style from './Style.module.css';
import { useState, useEffect } from 'react';
import Modal from '../reusables/modal/Modal';
import { TextField, Zoom, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import NotMatchPage from '../../components/notMatchPage/NotMatchPage';

function MailObservations() {
    const [uuid] = useState(useParams().uuid);
    const [observations, setObservations] = useState('');
    const [saving, setSaving] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [completed, setCompleted] = useState(false);

    const [existe, setExiste] = useState(true);

    useEffect(() => changeCampaignStatus(), []);

    function changeCampaignStatus(){
        fetch(api.urlFileCampaign+uuid+'/')
        .then((data) => {
            if (data.status !== 200){
                setExiste(false);
                msg.error('Se ha producido un error')
            } else {setExiste(true);}

        })
        //.catch(console.log)
    }

    function confirm(){
        setSaving(true)
    }

    function cancel(){
        setSaving(false)
    }

    function register(){
        setProcessing(true)
        setSaving(false)
        fetch(api.urlFileCampaign+uuid+'/', {
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'observation': observations
            })
        })
        .then((data) => {
            if (data.status === 200){
                setProcessing(false)
                setCompleted(true)
                msg.success('Registro exitoso')
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function handleChange(event){
        setObservations(event.target.value);
    }

    return (
        <>
            {!existe ? <NotMatchPage/> :
            <Zoom in={true}>
                <div className={Style.container}>
                    <div>
                        <br/>
                        <img src="../logo.png" alt="Sistemas Aplicativos, S.A." />
                        <br/><br/>
                        <div className={Style.paper}>
                            {!completed ? 
                                <>
                                    <span className={Style.title}>Observaciones</span>
                                    <div className={Style.detail}>Ind&iacute;canos por qu&eacute; este tipo de archivo est&aacute; permitido.</div>
                                    <br/><br/>
                                    <TextField value={observations} onChange={handleChange} multiline variant="outlined" inputProps={{maxLength:1000}} autoFocus={true}/>
                                    <br/><br/><br/>
                                    <Button
                                        onClick={confirm}
                                        disabled={observations === '' ? Boolean(true) : Boolean(false)}
                                        className={Style.btn+' '+Style.fullWidth+' '+(processing ? Style.processing : '')} 
                                        variant="outlined" 
                                        color="secondary" 
                                        size="large">
                                        Registrar
                                    </Button>
                                    <br/><br/>
                                </>
                            :
                                <>
                                    <br/><br/>
                                    <span className={Style.title}>Gracias por la informaci&oacute;n</span>
                                    <br/><br/>
                                    <div className={Style.detail}>Nuestro equipo estar&aacute; analizando los datos proporcionados, y si fuera necesario, te estaremos contactando.</div>
                                    <br/><br/>
                                </>
                            }
                        </div>
                        <br/>
                        <div className={Style.copyright}>
                            All rights reserved. ® Copyright {new Date().getFullYear()}.
                            <br/>
                            Sistemas Aplicativos, S.A.
                        </div>
                        <br/>
                    </div>
                </div>
            </Zoom>}
            {saving ?
                <Modal
                    title="Confirmaci&oacute;n"
                    content="¿ Deseas realmente registrar estas observaciones ?"
                    secondaryActionName="Cancelar"
                    primaryActionName="Registrar"
                    secondaryAction={cancel}
                    primaryAction={register}
                    primaryActionDisabled={false}/>
            : <></>}
        </>
    );
}

export default MailObservations;