import { useState, useEffect } from 'react';
import Style from './Style.module.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Zoom from '@material-ui/core/Zoom';
import DuoVerification from './duoVerification/DuoVerification';
import { api } from './../../Config';

import CSRFToken from '../reusables/CSRFToken/CSRFToken'; //comentado prueba

function Login() {
    const [message, setMessage] = useState('Ingresa tu usuario y contraseña');
    const [msgType, setMsgType] = useState('default');
    const [authenticating, setAuthenticating] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [form, setForm] = useState({
        username: '',
        password: ''
    });

    useEffect(() => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
    }, []);

    const handleChange = event => {
        if (event.key === 'Enter'){
            submit()
        } else {
            setForm({ ...form, [event.target.name]: event.target.value });
        }
    };

    const submit = event => {
        setAuthenticating(true)
        const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value; //comentado prueba
        if (form.username !== '' && form.password !== '') {
            //urlLogin: urlBase+'../api-emailsecuritytsting/auth/login/
            fetch(api.urlLogin, { 
                method: 'post', 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken_pag, //comentado prueba
                },
                credentials: 'include', //comentado prueba
                body: JSON.stringify({
                    'username': form.username,
                    'password': form.password
                })
            })
            .then((data) => {
                if (data.status === 202){
                    setAuthenticated(true)
                } else if (data.status === 401) {
                    setMessage('Tu usuario y contraseña son incorrectos')
                    setMsgType('error')
                } else {
                    setMessage('Se ha producido un error')
                    setMsgType('error')
                }
                setAuthenticating(false)
            })
            //.catch(console.log)
        } else {
            setMessage('Ingresa tu usuario y contraseña')
            setMsgType('warning')
        }
    };

    return (
        <Zoom in={true}>
            <div className={Style.container}>
                <div>
                    <img src="logo.png" alt="Sistemas Aplicativos, S.A." />
                    <br/><br/>
                    <div className={Style.paper}>
                        {!authenticated ?
                            <>
                                {<CSRFToken/>}
                                <span className={Style.titulo}>Inicio de sesi&oacute;n</span>
                                <div className={msgType === 'default' ? Style.defaultMsg : msgType === 'error' ? Style.errorMsg : msgType === 'warning' ? Style.warningMsg : ''}>
                                    {message}
                                </div>
                                <br />
                                <TextField label="Usuario" name="username" onKeyUp={handleChange} inputProps={{maxLength:25}} autoFocus={true}/>
                                <br/><br/>
                                <TextField label="Contrase&ntilde;a" type="password" name="password" onKeyUp={handleChange} inputProps={{maxLength:25}}/>
                                <br/><br/><br/>
                                <Button onClick={submit} disabled={form.username === '' || form.password === '' ? Boolean(true) : Boolean(false)} className={Style.btnLogin+' '+Style.fullWidth+' '+(authenticating ? Style.authenticating : '')} variant="outlined" color="secondary" size="large">Iniciar Sesi&oacute;n</Button>
                                <br/><br/>
                            </>
                        : <DuoVerification username={form.username} password={form.password}/>}
                    </div>
                    <br/>
                    <div className={Style.copyright}>
                        All rights reserved. ® Copyright {new Date().getFullYear()}.
                        <br/>
                        Sistemas Aplicativos, S.A.
                    </div>
                </div>
            </div>
        </Zoom>
    );
}

export default Login;