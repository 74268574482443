import Style from './Style.module.css';
import {ExitToApp, Person, ExpandLess, ExpandMore, DragHandle, Link as LinkIcon, Menu as MenuIcon} from '@material-ui/icons';
import {Group, Business, AttachFile, ContactMail, CancelScheduleSend, Build,
    ArtTrack, Description, SupervisedUserCircle
} from '@material-ui/icons';

import { Link } from "react-router-dom";
import { homePage } from './../../../Config';
import {Tooltip, Drawer, Popper, Paper, MenuList, MenuItem, ClickAwayListener, Container, IconButton, List, ListItem, ListItemIcon, ListItemText, Collapse, Divider} from '@material-ui/core';

import { api } from '../../../Config';
import { useEffect, useState } from 'react';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../../components/reusables/toast/Toast';

function Authenticated(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [option, setOption] = useState(0);
    const [option_rep, setOption_rep] = useState(0);

    const [permissions, setPermissions] = useState([]);

  useEffect(() => getPermissions(), []);

  function getPermissions(){
    if (!localStorage.getItem("accessToken")){
      return
    }
    //setProcessing(true)
    fetch(api.urlGetPermissions, {
        headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
    })
    .then((data) => {
        if (data.status === 200){
            data.json().then((data) => {
                setPermissions(data.permissions || [])
                //setProcessing(false)
            })
        } else if (data.status === 401 || data.status === 403) {
            RefreshToken()
            getPermissions()
        } else {
            //setProcessing(false)
            msg.error('Se ha producido un error')
        }
    })
  }


    const handleClick = event => { 
        setAnchorEl(event.currentTarget)
    }

    const handleClose = e => {
        setAnchorEl(null);
    }

    function openOption(val){
        if (val === option) {
            setOption(0)
        } else {
            setOption(val)
        }
    }

    function openOption_rep(val){
        if (val === option_rep) {
            setOption_rep(0)
        } else {
            setOption_rep(val)
        }
    }

    return (
        <>
            <div className={Style.header}>
                <Container maxWidth="lg">
                    <Tooltip title="Ir a la p&aacute;gina principal" placement="right" arrow>
                        {/*<Link onClick={() => window.location.href = homePage} className={Style.logo}>
                            <img src="logo.png" alt="Sistemas Aplicativos, S. A."/>
                        </Link>*/}
                        <Link to={homePage} className={Style.logo}>
                            <img src="logo.png" alt="Sistemas Aplicativos, S. A."/>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Men&uacute; de opciones" placement="right" arrow>
                        <IconButton onClick={() => setShowMenu(true)} className={Style.btnMenu}>
                            <MenuIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Mi perfil" placement="left" arrow>
                        <IconButton onClick={handleClick} className={Style.btnProfile}>
                            <Person />
                        </IconButton>
                    </Tooltip>
                </Container>
                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-end" transition className={Style.profile}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={Boolean(anchorEl)}>
                                <div className={Style.userData}>
                                    <div className={Style.userName}>{localStorage.getItem("firstName")+' '+localStorage.getItem("lastName")}</div>
                                    <div className={Style.userDepartment}>{localStorage.getItem("department")}</div>
                                </div>
                                <Divider />
                                <br/>
                                <MenuItem onClick={() => window.location.href='login'}>
                                    <ListItemIcon><ExitToApp/></ListItemIcon>
                                    <ListItemText primary="Cerrar Sesi&oacute;n" />
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </div>
            {props.children}
            <Drawer open={showMenu} onClose={() => setShowMenu(false)}>
                <List component="nav">

                    {permissions.includes("Administracion.view_user") ?
                        <>
                            <Link to="user-management">
                                <ListItem button>
                                    <ListItemIcon className={Style.option}><SupervisedUserCircle /></ListItemIcon>
                                    <ListItemText primary="Gestión de usuarios"/>
                                </ListItem>
                            </Link>
                            <Divider/>
                        </>
                    : null }

                    {/*localStorage.getItem("permissions") && localStorage.getItem("permissions").split(',')*/ permissions.includes("Administracion.view_acceptanceparameters") ?
                        <>
                            <Link to="acceptance-parameters">
                                <ListItem button>
                                    <ListItemIcon className={Style.option}><Build /></ListItemIcon>
                                    <ListItemText primary="Parámetros de aceptación"/>
                                </ListItem>
                            </Link>
                            <Divider/>
                        </>
                    : null }

                    {permissions.includes("Administracion.view_industry") ?
                        <>
                            <Link to="industry-management">
                                <ListItem button>
                                    <ListItemIcon className={Style.option}><Business /></ListItemIcon>
                                    <ListItemText primary="Gestión de industrias"/>
                                </ListItem>
                            </Link>
                            <Divider/>
                        </>
                    : null }

                    {permissions.includes("Administracion.view_client") ?
                        <>
                            <Link to="client-management">
                                <ListItem button>
                                    <ListItemIcon className={Style.option}><Group /></ListItemIcon>
                                    <ListItemText primary="Gesti&oacute;n de clientes"/>
                                </ListItem>
                            </Link>
                            <Divider/>
                        </>
                    : null }
                    {(permissions.includes("mail_check.view_file") || permissions.includes("mail_check.view_campaign")) ?
                        <>
                            <ListItem button onClick={() => openOption(1)}>
                                <ListItemIcon><ContactMail /></ListItemIcon>
                                <ListItemText primary="Pruebas de correo"/>
                                {option === 1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Divider/>
                            <Collapse in={option === 1} timeout="auto" unmountOnExit>
                                {permissions.includes("mail_check.view_file") ?
                                    <Link to="file-management">
                                        <ListItem button>
                                            <ListItemIcon className={Style.option}><AttachFile /></ListItemIcon>
                                            <ListItemText primary="Gesti&oacute;n de archivos"/>
                                        </ListItem>
                                    </Link>
                                : null }
                                {permissions.includes("mail_check.view_campaign") ?
                                    <Link to="campaign-management">
                                        <ListItem button>
                                            <ListItemIcon className={Style.option}><CancelScheduleSend /></ListItemIcon>
                                            <ListItemText primary="Ejecuci&oacute;n de campa&ntilde;as"/>
                                        </ListItem>
                                    </Link>
                                : null }
                            </Collapse>
                        </>
                    : null }

                    {(permissions.includes("mail_check.view_file") || permissions.includes("mail_check.view_campaign")) ?
                        <>
                            <ListItem button onClick={() => openOption_rep(1)}>
                                <ListItemIcon><ArtTrack /></ListItemIcon>
                                <ListItemText primary="Reportes"/>
                                {option_rep === 1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Divider/>
                            <Collapse in={option_rep === 1} timeout="auto" unmountOnExit>
                                {permissions.includes("mail_check.view_campaign") ?
                                    <Link to="rep-historico-res">
                                        <ListItem button>
                                            <ListItemIcon className={Style.option}><Description /></ListItemIcon>
                                            <ListItemText primary="Rep. histórico resumido"/>
                                        </ListItem>
                                    </Link>
                                : null }
                                {permissions.includes("mail_check.view_campaign") ?
                                    <Link to="rep-historico-det">
                                        <ListItem button>
                                            <ListItemIcon className={Style.option}><Description /></ListItemIcon>
                                            <ListItemText primary="Rep. histórico detallado"/>
                                        </ListItem>
                                    </Link>
                                : null }
                                {permissions.includes("mail_check.view_campaign") ?
                                    <Link to="rep-comparativo-3">
                                        <ListItem button>
                                            <ListItemIcon className={Style.option}><Description /></ListItemIcon>
                                            <ListItemText primary="Rep. Comparativo"/>
                                        </ListItem>
                                    </Link>
                                : null }
                            </Collapse>
                        </>
                    : null }

                    {(permissions.includes("Phishing.view_campaignclient") || permissions.includes("Phishing.view_execution")) ?
                        <>
                            <ListItem button onClick={() => openOption(2)}>
                                <ListItemIcon><DragHandle /></ListItemIcon>
                                <ListItemText primary="Pruebas de phishing"/>
                                {option === 2 ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Divider/>
                            <Collapse in={option === 2} timeout="auto" unmountOnExit>
                                {permissions.includes("Phishing.view_campaignclient") ?
                                    <Link to="phishing-campaign">
                                        <ListItem button>
                                            <ListItemIcon className={Style.option}><LinkIcon /></ListItemIcon>
                                            <ListItemText primary="Gesti&oacute;n de campa&ntilde;as"/>
                                        </ListItem>
                                    </Link>
                                : null }
                                {permissions.includes("Phishing.view_execution") ?
                                    <Link to="phishing-campaign-execution">
                                        <ListItem button>
                                            <ListItemIcon className={Style.option}><LinkIcon /></ListItemIcon>
                                            <ListItemText primary="Ejecuci&oacute;n de campa&ntilde;as"/>
                                        </ListItem>
                                    </Link>
                                : null }
                            </Collapse>
                        </>
                    : null }
                </List>
            </Drawer>
        </>
    );
}

export default Authenticated;