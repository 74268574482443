import { api } from '../../Config';
import RefreshToken from '../../RefreshToken';
import * as msg from '../reusables/toast/Toast';
import Style from './Style.module.css';
import { useEffect, useState } from 'react';
import { Container, Card, CardContent, Grid, IconButton, Tooltip, Fab, Tab, Tabs, Divider, FormControl, InputLabel, Select, MenuItem, Checkbox } from '@material-ui/core';
import { Edit, Close, Add, ArrowDownward, PlaylistAddCheck, Description, PieChart, /*PictureAsPdf*/ } from '@material-ui/icons';
import { Link } from "react-router-dom";
import Authenticated from '../reusables/authenticated/Authenticated';
import Modal from '../reusables/modal/Modal';
import RegisterCampaign from './registerCampaign/RegisterCampaign';
import moment from 'moment';
import Loading from '../reusables/loading/Loading';
import NoDataToDisplay from '../reusables/noDataToDisplay/NoDataToDisplay';

import {VerifiedUser} from '@material-ui/icons';
import RegisterActivation from './registerActivation/RegisterActivation';

function CampaignManagement() {
    const [tab, setTab] = useState(0);
    const [campaign, setCampaign] = useState('');
    const [clientId, setClientId] = useState(localStorage.getItem('clientId'));
    const [campaigns, setCampaigns] = useState([]);
    const [clients, setClients] = useState([]);
    const [processing, setProcessing] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [typeCampaign, setTypeCampaign] = useState('Programada');

    const [activar, setActivar] = useState(false);

    const [form, setForm] = useState({
        campaign: ''
    });
    const [excelpar, setExcelpar] = useState(false);
    const [excelpar1, setExcelpar1] = useState(false);

    const [selectedCam, setSelectedCam] = useState([]);

    useEffect(() => getClients(), []);

    function handleChange(e, t) {
        setTab(t);
    }
    function handleChangePar(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }

    function handleClientChange(event) {
        setClientId(event.target.value)
        localStorage.setItem('clientId', event.target.value)
        if (event.target.value){
            localStorage.setItem('client_email', clients.find(c => c.id === event.target.value)?.email || '');
        }
        getCampaigns(typeCampaign)
    }

    function handleCheckChange(cam){
        if (selectedCam.includes(cam)) {
            setSelectedCam(
                selectedCam.filter(function(element){
                    return element !== cam
                })
            )
        } else {
            setSelectedCam(oldselectedCam => [...oldselectedCam, cam]);
        }
    }

    function getCampaigns(type) {
        setProcessing(true)
        setTypeCampaign(type)
        fetch(api.urlCampaigns + '?clientid=' + localStorage.getItem('clientId') + '&status=' + type, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        setCampaigns(data)
                        setProcessing(false)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getCampaigns(type)
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            //.catch(console.log)
    }

    function getClients() {
        setProcessing(true)
        fetch(api.urlClients, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        setClients(data)
                        if (clientId) {
                            getCampaigns('Programada')
                        } else {
                            msg.warning('Aún no has seleccionado un cliente')
                            setProcessing(false)
                        }
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getClients()
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            //.catch(console.log)
    }

    function hideModal() {
        setRegistering(false)
        setDeleting(false)

        setActivar(false)
        setExcelpar(false)
        setExcelpar1(false)
    }

    function confirmDelete(item) {
        setDeleting(true)
        setCampaign(item)
    }

    function del() {
        setProcessing(true)
        fetch(api.urlCampaigns + campaign.id+'/', {
            method: 'delete',
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 204) {
                    setDeleting(false)
                    getCampaigns(typeCampaign)
                    setProcessing(false)
                    msg.success('Cancelación exitosa')
                } else if (data.status === 401) {
                    RefreshToken()
                    del()
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            //.catch(console.log)
    }

    function update(element) {
        setRegistering(true)
        setCampaign(element)
    }

    function activarPrueba(element) {
        setActivar(true)
        setCampaign(element)
    }

    function Excelpar(element) {
        setExcelpar(true)
        setCampaign(element)
    }
    function Excelpar1(element) {
        setExcelpar1(true)
        setCampaign(element)
    }

    function register() {
        setRegistering(true)
        setCampaign('')
    }

    function download(e) {
        setCampaign(e)
        setDownloading(true)
        fetch(api.urlMailCheckDownload + e.id+'/', {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.blob().then((data) => {
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(data);
                        a.download = 'Resultados - ' + e.name + '.xlsx';
                        a.click();
                        window.URL.revokeObjectURL(a.href);
                        setDownloading(false)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    download(e)
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            //.catch(console.log)
    }

    function download_excel(e) {
        setCampaign(e)
        setDownloading(true)
        fetch(api.urlMailCheckDownloadExcel + e.id+'/', {
            method: 'post',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',},
            body: JSON.stringify({
                'campaigns': selectedCam
            })
        })
            .then((data) => {
                if (data.status === 200) {
                    data.blob().then((data) => {
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(data);
                        a.download = 'Resultados - ' + e.name + '.xlsx';
                        a.click();
                        window.URL.revokeObjectURL(a.href);
                        msg.success('Generación de documento exitoso')
                        setDownloading(false)
                        setExcelpar1(false)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    download_excel(e)
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            //.catch(console.log)
    }

    function download_pdf(e) {
        setCampaign(e)
        setDownloading(true)
        fetch(api.urlMailCheckDownloadGrafExcelPdf + e.id+ '?campaign=' + form.campaign /*+'/'*/, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.blob().then((data) => {
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(data);
                        a.download = 'EFC Resultados - ' + e.name + '.xlsm'/*'.xlsx'*/;
                        a.click();
                        window.URL.revokeObjectURL(a.href);
                        msg.success('Generación de documento exitoso')
                        setDownloading(false)
                        setExcelpar(false)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    download_pdf(e)
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            //.catch(console.log)
    }

    function consultar(){
        download_pdf(campaign);
    }

    function consultar_excel(){
        if (selectedCam.length === 0) {
            msg.warning('Debes seleccionar al menos una campaña')
            return
        }
        if (selectedCam.length > 1) {
            msg.warning('Debes seleccionar al menos de 1 campaña')
            return
        }

        //setRegistering(false)
        download_excel(campaign)
    }
    
    return (
        <Authenticated>
            <div className={Style.selectClient}>
                <Container maxWidth="lg">
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Cliente</InputLabel>
                        <Select value={clientId} name="client" onChange={handleClientChange} label="Cliente">
                        
                            {clients.map((element, index) => (
                                <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Container>
            </div>
            {processing && !registering && !deleting && !clientId ?
                <Container maxWidth="lg">
                    <br /><br /><br /><br /><br />
                    <Loading variant="tabbedList" height={50} />
                </Container>
                :
                clientId ?
                    <>
                        <br /><br /><br /><br />
                        <Container maxWidth="lg">
                            <Tabs
                                value={tab}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                variant="scrollable"
                                scrollButtons="auto">
                                <Tab onClick={() => getCampaigns('Programada')} label={typeCampaign === 'Programada' ? 'Campañas Programadas' : 'Programadas'} />
                                <Tab onClick={() => getCampaigns('Ejecutada')} label={typeCampaign === 'Ejecutada' ? 'Campañas Ejecutadas' : 'Ejecutadas'} />
                                <Tab onClick={() => getCampaigns('Cancelada')} label={typeCampaign === 'Cancelada' ? 'Campañas Canceladas' : 'Canceladas'} />
                            </Tabs>
                            <Divider />
                            <br />
                            {processing && !registering && !deleting ?
                                <Loading variant="list" column={1} height={50} />
                                :
                                campaigns.length > 0 ?
                                    campaigns.map((element, index) => (
                                        <div key={index}>
                                            <Card className={Style.item}>
                                                <CardContent>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={typeCampaign === 'Cancelada' ? 6 : 4}>
                                                            <div className={Style.title}>{element.name}</div>
                                                            <div className={Style.detail}>{moment(element.execution_date).format('DD-MMM-YYYY hh:mm A')}</div>
                                                            <div className={Style.detail}>{element.files.length} Archivo(s)</div>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <div className={Style.title}>{element.status_cam_des}</div>
                                                            <div className={Style.detail}>{element.addressee}</div>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <div className={Style.detail}>{element.mails_sent} Enviados</div>
                                                            <div className={Style.detail}>{element.pending_emails} Pendientes</div>
                                                            <div className={Style.detail}>{element.failed_emails} Fallidos</div>
                                                        </Grid>
                                                        {typeCampaign === 'Programada' ?
                                                            <Grid item xs={3} className={Style.options}>
                                                                {element.status_cam === 'P' ?
                                                                    <Tooltip title="Activar campaña" placement="left" arrow>
                                                                        <IconButton onClick={() => activarPrueba(element)}>
                                                                            <VerifiedUser />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                : <></>}
                                                                <Tooltip title="Editar campa&ntilde;a" placement="left" arrow>
                                                                    <IconButton onClick={() => update(element)}>
                                                                        <Edit />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Cancelar campa&ntilde;a" placement="left" arrow>
                                                                    <IconButton onClick={() => confirmDelete(element)}>
                                                                        <Close />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                            : <></>}
                                                        {typeCampaign === 'Ejecutada' ?
                                                            <Grid item xs={3} className={Style.options}>
                                                                <Link to={{ pathname: 'observations-files-received', campaign: element.id, campaign_name: element.name }}>
                                                                    <Tooltip title="Registrar observaciones de archivos recibidos" placement="left" arrow>
                                                                        <IconButton>
                                                                            <PlaylistAddCheck />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Link>
                                                                <Tooltip title="Descargar resultados en excel" placement="left" arrow>
                                                                    <IconButton onClick={() => download(element)} className={downloading && campaign.id === element.id ? Style.downloading : ''}>
                                                                        <ArrowDownward />
                                                                    </IconButton>
                                                                </Tooltip>

                                                                <Tooltip title="Descargar informe en excel" placement="left" arrow>
                                                                    {/*<IconButton onClick={() => download_excel(element)} className={downloading && campaign.id === element.id ? Style.downloading : ''}>
                                                                        <Description />
                                                                    </IconButton>*/}
                                                                    <IconButton onClick={() => Excelpar1(element)}>
                                                                        <Description />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Descargar informe pdf" placement="left" arrow>
                                                                    {/*<IconButton onClick={() => download_pdf(element)} className={downloading && campaign.id === element.id ? Style.downloading : ''}>
                                                                        <PieChart />
                                                                    </IconButton>*/}
                                                                    <IconButton onClick={() => Excelpar(element)}>
                                                                        <PieChart />
                                                                    </IconButton>
                                                                    
                                                                </Tooltip>

                                                            </Grid>
                                                            : <></>}
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                            <br />
                                        </div>
                                    ))
                                    : <NoDataToDisplay />
                            }
                        </Container>
                        <br /><br />
                        {typeCampaign === 'Programada' ?
                        <Tooltip title="Programar nueva campa&ntilde;a" placement="left" arrow>
                            <Fab onClick={register} color="secondary">
                                <Add fontSize="large" />
                            </Fab>
                        </Tooltip>
                        :<></>}
                        {registering ? <RegisterCampaign campaign={campaign} getCampaigns={getCampaigns} typeCampaign={typeCampaign} hideModal={hideModal} /> : <></>}
                        {activar ? <RegisterActivation campaign={campaign} getCampaigns={getCampaigns} typeCampaign={typeCampaign} hideModal={hideModal} /> : <></>}
                        {deleting ?
                            <Modal
                                title="Confirmaci&oacute;n"
                                content="¿ Deseas realmente continuar con la cancelaci&oacute;n ?"
                                secondaryActionName="Cancelar"
                                primaryActionName="Continuar"
                                secondaryAction={hideModal}
                                primaryAction={del}
                                primaryActionDisabled={false}
                                processing={processing} />
                            : <></>}
                    </>
                    : <></>
            }

        {excelpar ?
            <Modal
                title='Parámetros: campaña anterior'
                secondaryActionName="Cancelar"
                primaryActionName='Aceptar'
                secondaryAction={hideModal}
                primaryAction={consultar}
                primaryActionDisabled={form.campaign === '' }
                processing={processing}
                maxWidth="sm">

                <br/>                
                <br/>
                <Container maxWidth="lg">
                <InputLabel>Campaña a comparar</InputLabel>
                <br/>
                    <FormControl variant="outlined" size="small">
                        
                        <Select required value={form.campaign} name="campaign" onChange={handleChangePar} label="Campaña">
                            
                            {campaigns.map((element, index) => (
                                <MenuItem key={index} value={element.id}>{element.name} | {moment(element.execution_date).format('DD/MM/YYYY HH:mm')}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                </Container>
                <br/><br/>

            </Modal>
            : <></>}


        {excelpar1 ?
            <Modal
                title='Parámetros: campañas comparativas'
                secondaryActionName="Cancelar"
                primaryActionName='Aceptar'
                secondaryAction={hideModal}
                primaryAction={consultar_excel}
                processing={processing}
                maxWidth="sm">

                <br/>                
                <Container maxWidth="lg">
                <InputLabel>Campañas</InputLabel>
                    <FormControl variant="outlined" size="small">

                        {campaigns.length > 0 ?
                            campaigns.map((campaign, index) => (
                                <div key={index}>
                                    <Card className={Style.item}>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={2}>
                                                    <Checkbox checked={selectedCam.includes(campaign.id)} onChange={() => handleCheckChange(campaign.id)}/>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div className={Style.title}>{campaign.name}</div>
                                                    <div className={Style.detail}>{campaign.addressee}</div>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div className={Style.detail}>{moment(campaign.execution_date).format('DD/MM/YYYY HH:mm')}</div>
                                                </Grid>
                                                
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <br/>
                                </div>
                            ))
                        : <Loading variant="list" column={1} height={50}/>}

                    </FormControl>
                </Container>
                <br/><br/>

            </Modal>
            : <></>}

        </Authenticated>
    );
}

export default CampaignManagement;