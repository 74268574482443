// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../DesignConstants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_btnInputFile__2lLqg{\n    float: right;\n    margin-top: -35px !important;\n    margin-right: 5px !important;\n}\n\n.Style_inputLabel__3E9Lo>div{\n    padding-right: 25px;\n}", "",{"version":3,"sources":["webpack://src/components/reusables/inputFile/Style.module.css"],"names":[],"mappings":"AAEA;IACI,YAAY;IACZ,4BAA4B;IAC5B,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["@import '../../../DesignConstants.css';\n\n.btnInputFile{\n    float: right;\n    margin-top: -35px !important;\n    margin-right: 5px !important;\n}\n\n.inputLabel>div{\n    padding-right: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnInputFile": "Style_btnInputFile__2lLqg",
	"inputLabel": "Style_inputLabel__3E9Lo"
};
export default ___CSS_LOADER_EXPORT___;
