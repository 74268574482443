import { api } from '../../Config';
import RefreshToken from '../../RefreshToken';
import * as msg from '../reusables/toast/Toast';
import { useEffect, useState } from 'react';
import { Container, FormControl, InputLabel, Select, MenuItem, IconButton } from '@material-ui/core';
import Style from './Style.module.css';
import { Card, CardContent, Grid, Checkbox } from '@material-ui/core';
import Authenticated from '../reusables/authenticated/Authenticated';
import PageTitle from '../reusables/pageTitle/PageTitle';
import Modal from '../reusables/modal/Modal';
import Loading from '../reusables/loading/Loading';
import {Build} from '@material-ui/icons';

import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

function RepComparativo3() {
    const classes = useStyles();

    const [elements, setElements] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [registering, setRegistering] = useState(true);
    const [clients, setClients] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [enca, setEnca] = useState([]);
    const [client, setClient] = useState('');

    const [selectedCam, setSelectedCam] = useState([]);

    const [form, setForm] = useState({
        client: '',
        campaign: ''
    });

    useEffect(() => getClients(), []);

    function handleCheckChange(cam){
        if (selectedCam.includes(cam)) {
            setSelectedCam(
                selectedCam.filter(function(element){
                    return element !== cam
                })
            )
        } else {
            setSelectedCam(oldselectedCam => [...oldselectedCam, cam]);
        }
    }


    function getClients() {
        //setProcessing(true)
        fetch(api.urlClients, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        setClients(data)
        
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getClients()
                } else {
                    msg.error('Se ha producido un error al obtener clientes')
                }
            })
            //.catch(console.log)
    }

    function getCampaigns(client, type='Ejecutada') {
        setProcessing(true)
        fetch(api.urlCampaigns + '?clientid=' + client + '&status=' + type, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        setCampaigns(data)
                        setProcessing(false)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getCampaigns(client, type)
                } else {
                    msg.error('Se ha producido un error')
                    setProcessing(false)
                }
            })
            //.catch(console.log)
    }

    function hideModal(){
        setRegistering(false)
    }
    function parametros(){
        setRegistering(true)
    }

    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
        if(event.target.name =='client' && event.target.value){
            getCampaigns(event.target.value)
            setSelectedCam([])
        } 
    }
    function consultar(){
        if (selectedCam.length === 0) {
            msg.warning('Debes seleccionar al menos una campaña')
            return
        }
        if (selectedCam.length > 3) {
            msg.warning('Debes seleccionar al menos de 3 campaña')
            return
        }

        if (form.client){
            let cliente = clients.find(p => p.id === form.client);
            setClient(cliente);
        }
        setRegistering(false)
        getElements();
    }
    function getElements(){
        setProcessing(true)
        fetch(api.urlRepComparativo3+ '?clientid=' + form.client, {
            method: 'post',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',},
            body: JSON.stringify({
                'campaigns': selectedCam
            })
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setElements(data.data)
                    setEnca(data.enca)
                    setProcessing(false)
                })
            } else if (data.status === 401) {
                RefreshToken()
                getElements()
            } else {
                setProcessing(false)
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    return (
        <Authenticated>
            <br/><br/><br/>
            <Container maxWidth="lg">
                <PageTitle primary="Reporte comparativo últimas 3" secondary="los campañas realizadas por el cliente comparandolas con las últimas tres pruebas"/>
                <br/>

                <IconButton onClick={() => parametros()}><Build/>  Cliente: {client.name}</IconButton>
                {processing?
                    <Loading variant="list" column={1} height={50}/>
                :
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                        {enca.map((row) => (
                        <TableRow>
                            <StyledTableCell>{row.col0}</StyledTableCell>
                            <StyledTableCell align="right">{row.fecha?moment(row.col1).format('DD/MM/YYYY HH:mm'):row.col1}</StyledTableCell>
                            <StyledTableCell align="right">{row.fecha?moment(row.col2).format('DD/MM/YYYY HH:mm'):row.col2}</StyledTableCell>
                            <StyledTableCell align="right">{row.fecha?moment(row.col3).format('DD/MM/YYYY HH:mm'):row.col3}</StyledTableCell>

                        </TableRow>
                        ))}
                        </TableHead>
                        <TableBody>
                        {elements.map((row) => (
                            <StyledTableRow
                            key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.col0}
                            </TableCell>
                            <TableCell align="right">{row.col1}</TableCell>
                            <TableCell align="right">{row.col2}</TableCell>
                            <TableCell align="right">{row.col3}</TableCell>
                                        
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    }
            </Container>

            {registering ?
            <Modal
                title='Parámetros'
                secondaryActionName="Cancelar"
                primaryActionName='Aceptar'
                secondaryAction={hideModal}
                primaryAction={consultar}
                primaryActionDisabled={form.client === '' }
                processing={processing}
                maxWidth="sm">

                <br/>
                <Container maxWidth="lg">
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Cliente</InputLabel>
                        <Select required value={form.client} name="client" onChange={handleChange} label="Cliente">
                            
                            {clients.map((element, index) => (
                                <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Container>
                <br/>

                
                <br/>
                <Container maxWidth="lg">
                <InputLabel>Campañas</InputLabel>
                    <FormControl variant="outlined" size="small">

                        {campaigns.length > 0 ?
                            campaigns.map((campaign, index) => (
                                <div key={index}>
                                    <Card className={Style.item}>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={2}>
                                                    <Checkbox checked={selectedCam.includes(campaign.id)} onChange={() => handleCheckChange(campaign.id)}/>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div className={Style.title}>{campaign.name}</div>
                                                    <div className={Style.detail}>{campaign.addressee}</div>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div className={Style.detail}>{moment(campaign.execution_date).format('DD/MM/YYYY HH:mm')}</div>
                                                </Grid>
                                                
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <br/>
                                </div>
                            ))
                        : <Loading variant="list" column={1} height={50}/>}

                    </FormControl>
                </Container>
                <br/><br/>

            </Modal>
            : <></>}
        </Authenticated>
      );
};

export default RepComparativo3;