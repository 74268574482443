import { api } from '../../../Config';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Modal from '../../reusables/modal/Modal';
import Divider from '@material-ui/core/Divider';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import CSRFToken from '../../reusables/CSRFToken/CSRFToken';

function Register(props) {
    const [processing, setProcessing] = useState(false);
    const [result_types, setResult_types] = useState([]);
    const [form, setForm] = useState({
        name: '',
        description: '',
        result_type: ''
    });

    useEffect(() => {

        getResult_types();
        if (props.element !== '') {
            setForm({
                name: props.element.name || '',
                description: props.element.description || '',

                result_type: props.element.result_type || ''
            })
        }
    }, []);

    function getResult_types(){
        fetch(api.urlAcceptanceParameters, {
            method: 'options',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setResult_types(data.actions.POST.result_type.choices);
                })
            } else if (data.status === 401) {
                RefreshToken()
                getResult_types()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }

    function register(){
        setProcessing(true)
        const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value;
        fetch(api.urlAcceptanceParameters+(props.element !== '' ? props.element.id+'/' : ''), {
            method: (props.element !== '' ? 'put' : 'post'),
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken_pag},
            credentials: 'include',
            body: JSON.stringify(form)
        })
        .then((data) => {
            if (data.status === 201 || data.status === 200){
                props.getElements()
                props.hideModal()
                setProcessing(false)
                msg.success(props.element !== '' ? 'Actualización exitosa' : 'Registro exitoso')
            } else if (data.status === 401) {
                RefreshToken()
                register()
            } else {
                data.json().then((data) => {
                    if (data.name){
                        msg.error(data.name)
                    } else {
                        msg.error('Se ha producido un error')
                    }
                })
                setProcessing(false)
            }
        })
        //.catch(console.log)
    }
    return (
        <Modal
            title={props.element === '' ? 'Registro' : 'Actualización'}
            secondaryActionName="Cancelar"
            primaryActionName={props.element === '' ? 'Registrar' : 'Actualizar'}
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={form.name === '' }
            processing={processing}
            maxWidth="sm">

            <CSRFToken />
            Parametro<Divider variant="inset"/>
            <br/>
            <TextField required value={form.name} onChange={handleChange} label="Nombre" name="name" variant="outlined" size="small" inputProps={{maxLength:150}} autoFocus={true}/>
            <br/><br/>
            <FormControl variant="outlined" size="small">
                <InputLabel>Tipo resultado</InputLabel>
                <Select value={form.result_type} name="result_type" onChange={handleChange} label="Tipo resultado">
                    {result_types.map((result_type, index) => (
                        <MenuItem key={index} value={result_type.value}>{result_type.display_name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br/><br/>

            <TextField value={form.description} onChange={handleChange} label="Descripción" name="description" variant="outlined" size="small" inputProps={{maxLength:500}}/>
            <br/><br/>

        </Modal>
    );
}
export default Register;