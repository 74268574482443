import { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export const error = (msg) => {
  setToastMessage('error', msg, true)
}

export const warning = (msg) => {
  setToastMessage('warning', msg, true)
}

export const info = (msg) => {
  setToastMessage('info', msg, true)
}

export const success = (msg) => {
  setToastMessage('success', msg, true)
}

const setToastMessage = (severity, message, show) => {
  localStorage.setItem('toastSeverity', severity)
  localStorage.setItem('toastMessage', message)
  localStorage.setItem('toastShow', show)
}

export default function Toast() {
  const [show, setShow] = useState(false);
  const [severity, setSeverity] = useState('info');
  const [message, setMessage] = useState('');

  useEffect(() => getMessage(), []);

  const getMessage = () => {
    setTimeout(function(){
      setSeverity(localStorage.getItem('toastSeverity'))
      setMessage(localStorage.getItem('toastMessage'))
      setShow(localStorage.getItem('toastShow') === 'true' ? true : false)
      getMessage()
    }, 1000);
  }

  const hide = () => {
    localStorage.setItem('toastShow', false)
  }

  return (
    <Snackbar open={show} autoHideDuration={6000} onClose={hide}>
        <Alert severity={severity} onClose={hide} variant="filled" elevation={6}>{message}</Alert>
    </Snackbar>
  );
};