// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../DesignConstants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_downloadFormat__1rKY3{\n    float: right;\n    margin-top: 5px;\n    margin-bottom: 10px;\n    color: var(--subtitleColor);\n    font-size: 12px;\n    transition: 0.2s;\n}\n\n.Style_downloadFormat__1rKY3:hover{\n    color: var(--secondaryColor);\n}\n\n.Style_help__3EWUM{\n    padding: 0 10px;\n}", "",{"version":3,"sources":["webpack://src/components/phishingCampaignExecution/register/Style.module.css"],"names":[],"mappings":"AAEA;IACI,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,eAAe;AACnB","sourcesContent":["@import './../../../DesignConstants.css';\n\n.downloadFormat{\n    float: right;\n    margin-top: 5px;\n    margin-bottom: 10px;\n    color: var(--subtitleColor);\n    font-size: 12px;\n    transition: 0.2s;\n}\n\n.downloadFormat:hover{\n    color: var(--secondaryColor);\n}\n\n.help{\n    padding: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadFormat": "Style_downloadFormat__1rKY3",
	"help": "Style_help__3EWUM"
};
export default ___CSS_LOADER_EXPORT___;
