import Style from './Style.module.css';
import {Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, Tooltip} from '@material-ui/core';
import {Help} from '@material-ui/icons';

function Modal(props) {
    return (
        <Dialog open={true} fullWidth={props.maxWidth ? true : false} maxWidth={props.maxWidth}>
            <DialogTitle>
                {props.title}
                {props.help ? 
                    <Tooltip title={props.help} placement="left" arrow>
                        <Help className={Style.btnHelp}/>
                    </Tooltip>
                : null}
            </DialogTitle>
            <DialogContent>
                {props.content ?
                    <DialogContentText>{props.content}</DialogContentText>
                :
                    props.children
                }
            </DialogContent>
            <DialogActions className={Style.actions}>
                <Button onClick={props.secondaryAction}>{props.secondaryActionName}</Button>
                <Button onClick={props.primaryAction} disabled={Boolean(props.primaryActionDisabled)} className={props.processing ? Style.processing : ''} color="secondary" variant="outlined">{props.primaryActionName}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default Modal;