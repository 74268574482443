// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_item__3Q1EP{\n    box-shadow: 0px 0px 10px #ccc6 !important;\n    transition: 0.3s !important;\n}\n\n.Style_item__3Q1EP:hover{\n    opacity: 1 !important;\n    box-shadow: 0px 2px 40px #ccc6 !important;\n}\n\n.Style_item__3Q1EP>div{\n    padding: 5px !important;\n}\n\n.Style_item__3Q1EP>div>div{\n    display: flex;\n    align-items: center;\n    text-align: left;\n}\n\n.Style_item__3Q1EP>div>div>div>.Style_title__35WFX{\n    font-weight: bold;\n}\n\n.Style_item__3Q1EP>div>div>div>.Style_detail__3g3IO{\n    font-size: 12px;\n    color: gray;\n}", "",{"version":3,"sources":["webpack://src/components/reports/Style.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":[".item{\n    box-shadow: 0px 0px 10px #ccc6 !important;\n    transition: 0.3s !important;\n}\n\n.item:hover{\n    opacity: 1 !important;\n    box-shadow: 0px 2px 40px #ccc6 !important;\n}\n\n.item>div{\n    padding: 5px !important;\n}\n\n.item>div>div{\n    display: flex;\n    align-items: center;\n    text-align: left;\n}\n\n.item>div>div>div>.title{\n    font-weight: bold;\n}\n\n.item>div>div>div>.detail{\n    font-size: 12px;\n    color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "Style_item__3Q1EP",
	"title": "Style_title__35WFX",
	"detail": "Style_detail__3g3IO"
};
export default ___CSS_LOADER_EXPORT___;
