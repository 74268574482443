import React, { useState, useEffect } from 'react';
import { api } from './../../../Config';
import * as msg from '../../reusables/toast/Toast';

const CSRFToken = () => {
    const [csrftoken, setcsrftoken] = useState('');

    /*const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }*/

    useEffect(() => {
        /*const fetchData = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_API_URL}/accounts/csrf_cookie`);
            } catch (err) {
            }
        };
        fetchData();
        setcsrftoken(getCookie('csrftoken'));*/

        fetch(api.urlCsrfToken, {
            method: 'GET',
            credentials: 'include' // Esto asegura que se incluyan las cookies
        })
          .then((data) => {
            if (data.status === 200){
              data.json().then((data) => {
                //localStorage.setItem("csrftoken", data.csrftoken); 
                setcsrftoken(data.csrftoken);
              })

            } else {
              msg.warning('Csrf Token incorrecto')
            }
          })

    }, []);

    /*<input type='hidden' name='csrfmiddlewaretoken' value={csrftoken} />*/
    return (
        <input type='hidden' name='csrfmiddlewaretoken' value={csrftoken} />
    );
};

export default CSRFToken;