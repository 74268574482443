import { Skeleton } from "@material-ui/lab";
import { Grid } from '@material-ui/core';

function Loading(props) {
    return (
        props.variant === 'list' ?
            props.column === 1 ?
                <>
                    <Skeleton height={props.height} animation="wave"/><br/>
                    <Skeleton height={props.height} animation="wave"/><br/>
                    <Skeleton height={props.height} animation="wave"/><br/>
                    <Skeleton height={props.height} animation="wave"/><br/>
                    <Skeleton height={props.height} animation="wave"/>
                </>
            : props.column === 2 ?
                <Grid container spacing={3}>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}><Skeleton height={props.height} animation="wave"/></Grid>
                </Grid>
            : null
        : props.variant === 'tabbedList' ?
            <>
                <Grid container spacing={3}>
                    <Grid item xs={2}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={2}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={2}><Skeleton height={props.height} animation="wave"/></Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
                <br/>
                <Skeleton height={props.height} animation="wave"/><br/>
                <Skeleton height={props.height} animation="wave"/><br/>
                <Skeleton height={props.height} animation="wave"/><br/>
                <Skeleton height={props.height} animation="wave"/><br/>
                <Skeleton height={props.height} animation="wave"/>
            </>
        : null
    );
}

export default Loading;