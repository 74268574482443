import { api } from '../../Config';
import RefreshToken from '../../RefreshToken';
import * as msg from './../reusables/toast/Toast';
import Style from './Style.module.css';
import { useEffect, useState } from 'react';
import { Container, Card, CardContent, Grid, IconButton, Tooltip, Fab } from '@material-ui/core';
import {Edit, Close, Add} from '@material-ui/icons';
import Authenticated from './../reusables/authenticated/Authenticated';
import PageTitle from './../reusables/pageTitle/PageTitle';
import Modal from './../reusables/modal/Modal';
import RegisterFile from './registerFile/RegisterFile';
import Loading from '../reusables/loading/Loading';
import NoDataToDisplay from '../reusables/noDataToDisplay/NoDataToDisplay';

function FileManagement() {
    const [file, setFile] = useState('');
    const [files, setFiles] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [processing, setProcessing] = useState(true);

    useEffect(() => getFiles(), []);

    function getFiles(){
        setProcessing(true)
        fetch(api.urlFiles, {
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setFiles(data)
                    setProcessing(false)
                })
            } else if (data.status === 401) {
                RefreshToken()
                getFiles()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function hideModal(){
        setRegistering(false)
        setDeleting(false)
    }

    function confirmDelete(id){
        setDeleting(true)
        setFile(id)
    }

    function deleteFile(){
        setProcessing(true)
        fetch(api.urlFiles+file.id+'/', {
            method: 'delete',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 204){
                getFiles()
                setDeleting(false)
                setProcessing(false)
                msg.success('Eliminación exitosa')
            } else if (data.status === 401) {
                RefreshToken()
                deleteFile()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function update(file){
        setRegistering(true)
        setFile(file)
    }

    function register(){
        setRegistering(true)
        setFile('')
    }

    return (
        <Authenticated>
            <br/><br/><br/>
            <Container maxWidth="lg">
                <PageTitle primary="Gesti&oacute;n de archivos" secondary="Archivos para pruebas de filtrado de correo"/>
                <br/>

                {processing && !registering && !deleting ?
                    <Loading variant="list" column={1} height={50}/>
                :
                    files.length > 0 ?
                        files.map((file, index) => (
                            <div key={index}>
                                <Card className={Style.item}>
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5}>
                                                <div className={Style.title}>{file.name}</div>
                                                <div className={Style.detail}>{file.description}</div>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <div className={Style.detail}>{file.file_type_des}</div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className={Style.detail}>Formato {file.extension}</div>
                                                <div className={Style.detail}>Riesgo {file.risk}</div>
                                            </Grid>
                                            <Grid item xs={2} className={Style.options}>
                                                <Tooltip title="Editar archivo" placement="left" arrow>
                                                    <IconButton onClick={() => update(file)}>
                                                        <Edit/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Eliminar archivo" placement="left" arrow>
                                                    <IconButton onClick={() => confirmDelete(file)}>
                                                        <Close/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <br/>
                            </div>
                        ))
                    : <NoDataToDisplay/>
                }
            </Container>
            <br/><br/>
            <Tooltip title="Nuevo archivo" placement="left" arrow>
                <Fab onClick={register} color="secondary">
                    <Add fontSize="large" />
                </Fab>
            </Tooltip>
            {registering ? <RegisterFile file={file} getFiles={getFiles} hideModal={hideModal}/> : <></>}
            {deleting ?
                <Modal
                    title="Confirmaci&oacute;n"
                    content="¿ Deseas realmente continuar con la eliminaci&oacute;n ?"
                    secondaryActionName="Cancelar"
                    primaryActionName="Continuar"
                    secondaryAction={hideModal}
                    primaryAction={deleteFile}
                    primaryActionDisabled={false}
                    processing={processing}/>
            : <></>}
        </Authenticated>
    );
}

export default FileManagement;