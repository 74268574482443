import { api } from '../../Config';
import RefreshToken from '../../RefreshToken';
import * as msg from '../reusables/toast/Toast';
import Style from './Style.module.css';
import { useEffect, useState } from 'react';
import { Container, Card, CardContent, Grid, IconButton, Tooltip, Fab } from '@material-ui/core';
import {Edit, Close, Add} from '@material-ui/icons';
import Authenticated from '../reusables/authenticated/Authenticated';
import PageTitle from '../reusables/pageTitle/PageTitle';
import Modal from '../reusables/modal/Modal';
import Register from './register/Register';
import Loading from '../reusables/loading/Loading';
import NoDataToDisplay from '../reusables/noDataToDisplay/NoDataToDisplay';

function ParametersManagement() {
    const [element, setElement] = useState('');
    const [elements, setElements] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [processing, setProcessing] = useState(true);

    useEffect(() => getElements(), []);

    function getElements(){
        setProcessing(true)
        fetch(api.urlAcceptanceParameters, {
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setElements(data)
                    setProcessing(false)
                })
            } else if (data.status === 401) {
                RefreshToken()
                getElements()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function del(){
        setProcessing(true)
        fetch(api.urlAcceptanceParameters+element.id+'/', {
            method: 'delete',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 204){
                setDeleting(false)
                setProcessing(false)
                getElements()
                msg.success('Eliminación exitosa')
            } else if (data.status === 401) {
                RefreshToken()
                del()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function hideModal(){
        setRegistering(false)
        setDeleting(false)
    }

    function confirmDel(e){
        setDeleting(true)
        setElement(e)
    }

    function update(e){
        setRegistering(true)
        setElement(e)
    }

    function register(){
        setRegistering(true)
        setElement('')
    }


   return (
    <Authenticated>
        <br/><br/><br/>
        <Container maxWidth="lg">
            <PageTitle primary="Gestión de parámetros de aceptación" secondary="Parámetros de aceptación para el estado de cada correo enviado de las pruebas de campaña"/>
            <br/>

            {processing && !registering && !deleting ?
                <Loading variant="list" column={1} height={50}/>
            :
                elements.length > 0 ?
                    elements.map((item, index) => (
                        <div key={index}>
                            <Card className={Style.item}>
                                <CardContent>
                                    <Grid container spacing={4}>
                                        <Grid item xs={4}>
                                            <div className={Style.title}>{item.name}</div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div className={Style.detail}>{item.result_type_des}</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={Style.detail}>{item.description}</div>
                                        </Grid>
                                        <Grid item xs={2} className={Style.options}>
                                            <Tooltip title="Editar" placement="left" arrow>
                                                <IconButton onClick={() => update(item)}>
                                                    <Edit/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Eliminar" placement="left" arrow>
                                                <IconButton onClick={() => confirmDel(item)}>
                                                    <Close/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <br/>
                        </div>
                    ))
                : <NoDataToDisplay/>
            }
        </Container>
        <br/><br/>
        <Tooltip title="Nueva industria" placement="left" arrow>
            <Fab onClick={register} color="secondary">
                <Add fontSize="large" />
            </Fab>
        </Tooltip>
        
        {registering ? <Register element={element} getElements={getElements} hideModal={hideModal}/> : <></>}
        {deleting ?
            <Modal
                title="Confirmaci&oacute;n"
                content="¿ Deseas continuar con la eliminación ?"
                secondaryActionName="Cancelar"
                primaryActionName="Continuar"
                secondaryAction={hideModal}
                primaryAction={del}
                primaryActionDisabled={false}
                processing={processing}/>
        : <></>}
    </Authenticated>
  );

};

export default ParametersManagement;