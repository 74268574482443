// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_item__2oaxC{\r\n    box-shadow: 0px 0px 10px #ccc6 !important;\r\n    transition: 0.3s !important;\r\n}\r\n\r\n.Style_item__2oaxC:hover{\r\n    opacity: 1 !important;\r\n    box-shadow: 0px 2px 40px #ccc6 !important;\r\n}\r\n\r\n.Style_item__2oaxC>div{\r\n    padding: 5px !important;\r\n}\r\n\r\n.Style_item__2oaxC>div>div{\r\n    display: flex;\r\n    align-items: center;\r\n    text-align: left;\r\n}\r\n\r\n.Style_item__2oaxC>div>div>div>.Style_title__1eC6X{\r\n    font-weight: bold;\r\n}\r\n\r\n.Style_item__2oaxC>div>div>div>.Style_detail__1L0Aa{\r\n    font-size: 12px;\r\n    color: gray;\r\n}", "",{"version":3,"sources":["webpack://src/components/IndustryManagement/register/Style.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":[".item{\r\n    box-shadow: 0px 0px 10px #ccc6 !important;\r\n    transition: 0.3s !important;\r\n}\r\n\r\n.item:hover{\r\n    opacity: 1 !important;\r\n    box-shadow: 0px 2px 40px #ccc6 !important;\r\n}\r\n\r\n.item>div{\r\n    padding: 5px !important;\r\n}\r\n\r\n.item>div>div{\r\n    display: flex;\r\n    align-items: center;\r\n    text-align: left;\r\n}\r\n\r\n.item>div>div>div>.title{\r\n    font-weight: bold;\r\n}\r\n\r\n.item>div>div>div>.detail{\r\n    font-size: 12px;\r\n    color: gray;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "Style_item__2oaxC",
	"title": "Style_title__1eC6X",
	"detail": "Style_detail__1L0Aa"
};
export default ___CSS_LOADER_EXPORT___;
