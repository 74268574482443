import Style from './Style.module.css';
import Zoom from '@material-ui/core/Zoom';

function NotMatchPage() {
    return (
        <Zoom in={true}>
            <div className={Style.root}>
                <div className={Style.content}>
                    <img src="img/error.png" className={Style.img} alt="P&aacute;gina no encontrada"/>
                    <br/><br/>
                    <div className={Style.titulo}>Lo sentimos</div>
                    <div className={Style.descripcion}>&Eacute;sta p&aacute;gina no est&aacute; disponible</div>
                </div>
            </div>
        </Zoom>
    );
}

export default NotMatchPage;