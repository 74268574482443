import './App.css';
import 'fontsource-roboto';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { applicationContext } from './Config';
import Toast from './components/reusables/toast/Toast';
import NotMatchPage from './components/notMatchPage/NotMatchPage';
import NotAuthorized from './components/notAuthorized/NotAuthorized';
import HomePage from './components/homePage/HomePage';
import Login from './components/login/Login';
import CampaignManagement from './components/campaignManagement/CampaignManagement';
import FileManagement from './components/fileManagement/FileManagement';
import MailObservations from './components/mailObservations/MailObservations';
import ActivateUserAccount from './components/activateUser/ActivateUserAccount';
import PhishingCampaign from './components/phishingCampaign/PhishingCampaign';
import PhishingCampaignExecution from './components/phishingCampaignExecution/PhishingCampaignExecution';
import HtmlRenderer from './components/reusables/htmlRenderer/HtmlRenderer';
import ObservationsFilesReceived from './components/campaignManagement/observationsFilesReceived/ObservationsFilesReceived';
import ClientManagement from './components/clientManagement/ClientManagement';
import UserManagement from './components/userManagement/UserManagement';

import IndustryManagement from './components/IndustryManagement/IndustryManagement';
import ParametersManagement from './components/ParametersManagement/ParametersManagement';

import RepHistoricoDetallado from './components/reports/RepHistoricoDetallado';
import RepHistoricoResumen from './components/reports/RepHistoricoResumen';
import RepComparativo3 from './components/reports/RepComparativo3';

import { api } from './Config';
import { useEffect, useState } from 'react';
import RefreshToken from './RefreshToken';
import * as msg from './components/reusables/toast/Toast';

const theme = createMuiTheme({
  palette: {
    primary: {main: '#818286'},
    secondary: {main: '#ea2129'}
  },
});

function App() {

  const [permissions, setPermissions] = useState([]);

  useEffect(() => getPermissions(), []);

  function getPermissions(){
    if (!localStorage.getItem("accessToken")){
      return
    }
    //setProcessing(true)
    fetch(api.urlGetPermissions, {
        headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
    })
    .then((data) => {
        if (data.status === 200){
            data.json().then((data) => {
                setPermissions(data.permissions || [])
                //setProcessing(false)
            })
        } else if (data.status === 401 || data.status === 403) {
            RefreshToken()
            getPermissions()
        } else {
            //setProcessing(false)
            msg.error('Se ha producido un error')
        }
    })
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router basename={ applicationContext }>
          <Switch>
            <Route path="/login" exact>
              <Login/>
            </Route>
            <Route path="/" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ? <HomePage/> : <Redirect to="/login"/>}
            </Route>

            <Route path="/user-management" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("Administracion.view_user") ?
                  <UserManagement/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>

            <Route path="/industry-management" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("Administracion.view_industry") ?
                  <IndustryManagement/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>

            <Route path="/acceptance-parameters" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("Administracion.view_acceptanceparameters") ?
                  <ParametersManagement/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>

            <Route path="/client-management" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("Administracion.view_client") ?
                  <ClientManagement/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>
            <Route path="/file-management" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("mail_check.view_file") ?
                  <FileManagement/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>
            <Route path="/campaign-management" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("mail_check.view_campaign") ?
                  <CampaignManagement/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>
            <Route path="/mail-observations/:uuid" exact>
              <MailObservations/>
            </Route>
            <Route path="/activate/:uuid" exact>
              <ActivateUserAccount/>
            </Route>
            <Route path="/observations-files-received" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("mail_check.view_filecampaign") ?
                  <ObservationsFilesReceived/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>

            <Route path="/rep-historico-res" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("mail_check.view_campaign") ?
                  <RepHistoricoResumen/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>
            <Route path="/rep-historico-det" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("mail_check.view_campaign") ?
                  <RepHistoricoDetallado/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>

            <Route path="/rep-comparativo-3" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("mail_check.view_campaign") ?
                  <RepComparativo3/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>

            <Route path="/phishing-campaign" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("Phishing.view_campaignclient") ?
                  <PhishingCampaign/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>
            <Route path="/phishing-campaign-execution" exact>
              {localStorage.getItem("accessToken") && localStorage.getItem("refreshToken") ?
                permissions.includes("Phishing.view_execution") ?
                  <PhishingCampaignExecution/>
                : <NotAuthorized/>
              : <Redirect to="/login"/>}
            </Route>
            <Route path="/landing-page/:uuid/:action" exact>
              <HtmlRenderer type="LANDING-PAGE"/>
            </Route>
            <Route path="/awareness-page/:uuid/:action" exact>
              <HtmlRenderer type="AWARENESS"/>
            </Route>
            <Route>
              <NotMatchPage/>
            </Route>
          </Switch>
        </Router>
        <Toast/>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
