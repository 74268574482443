import { api } from '../../Config';
import RefreshToken from '../../RefreshToken';
import * as msg from '../reusables/toast/Toast';
import Style from './Style.module.css';
import { useEffect, useState } from 'react';
import { Container, Card, CardContent, Grid, IconButton, Tooltip, Fab, Tab, Tabs, Divider, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Edit, Close, Add, ArrowDownward } from '@material-ui/icons';
import Authenticated from '../reusables/authenticated/Authenticated';
import Modal from '../reusables/modal/Modal';
import Register from './register/Register';
import moment from 'moment';
import Loading from '../reusables/loading/Loading';
import NoDataToDisplay from '../reusables/noDataToDisplay/NoDataToDisplay';

function PhishingCampaignExecution() {
    const [tab, setTab] = useState(0);
    const [element, setElement] = useState('');
    const [elements, setElements] = useState([]);
    const [clientId, setClientId] = useState(localStorage.getItem('clientId'));
    const [clients, setClients] = useState([]);
    const [processing, setProcessing] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [typeElements, setTypeElements] = useState('Programadas');

    useEffect(() => getClients(), []);

    function handleChange(e, t) {
        setTab(t);
    }

    function handleClientChange(event) {
        setClientId(event.target.value)
        localStorage.setItem('clientId', event.target.value)
        getElements(typeElements)
    }

    function getElements(type) {
        setProcessing(true)
        setTypeElements(type)
        fetch(api.urlPhishingCampaignExecution + '?clientid=' + localStorage.getItem('clientId') + '&state=' + type, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        setElements(data)
                        setProcessing(false)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getElements(type)
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            .catch(console.log)
    }

    function getClients() {
        setProcessing(true)
        fetch(api.urlClients, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        setClients(data)
                        if (clientId) {
                            getElements('Programadas')
                        } else {
                            msg.warning('Aún no has seleccionado un cliente')
                            setProcessing(false)
                        }
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getClients()
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            .catch(console.log)
    }

    function hideModal() {
        setRegistering(false)
        setDeleting(false)
    }

    function confirmDelete(e) {
        setDeleting(true)
        setElement(e)
    }

    function del() {
        setProcessing(true)
        fetch(api.urlPhishingCampaignExecution + element.id, {
            method: 'delete',
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 204) {
                    setDeleting(false)
                    getElements(typeElements)
                    setProcessing(false)
                    msg.success('Cancelación exitosa')
                } else if (data.status === 401) {
                    RefreshToken()
                    del()
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            .catch(console.log)
    }

    function update(e) {
        setRegistering(true)
        setElement(e)
    }

    function register() {
        setRegistering(true)
        setElement('')
    }

    function download(e) {
        setElement(e)
        setDownloading(true)
        fetch(api.urlPhishingDownload + e.id, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.blob().then((data) => {
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(data);
                        a.download = 'Resultados - ' + e.name + '.xlsx';
                        a.click();
                        window.URL.revokeObjectURL(a.href);
                        setDownloading(false)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    download(e)
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            .catch(console.log)
    }

    return (
        <Authenticated>
            <div className={Style.selectClient}>
                <Container maxWidth="lg">
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Cliente</InputLabel>
                        <Select value={clientId} name="client" onChange={handleClientChange} label="Cliente">
                            {clients.map((item, index) => (
                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Container>
            </div>
            {processing && !registering && !deleting && !clientId ?
                <Container maxWidth="lg">
                    <br /><br /><br /><br /><br />
                    <Loading variant="tabbedList" height={50} />
                </Container>
                :
                clientId ?
                    <>
                        <br /><br /><br /><br />
                        <Container maxWidth="lg">
                            <Tabs
                                value={tab}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                variant="scrollable"
                                scrollButtons="auto">
                                <Tab onClick={() => getElements('Programadas')} label={typeElements === 'Programadas' ? 'Campañas Programadas' : 'Programadas'} />
                                <Tab onClick={() => getElements('Ejecutadas')} label={typeElements === 'Ejecutadas' ? 'Campañas Ejecutadas' : 'Ejecutadas'} />
                                <Tab onClick={() => getElements('Canceladas')} label={typeElements === 'Canceladas' ? 'Campañas Canceladas' : 'Canceladas'} />
                            </Tabs>
                            <Divider />
                            <br />
                            {processing && !registering && !deleting ?
                                <Loading variant="list" column={1} height={50} />
                                :
                                elements.length > 0 ?
                                    elements.map((item, index) => (
                                        <div key={index}>
                                            <Card className={Style.item}>
                                                <CardContent>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={typeElements === 'Canceladas' ? 6 : 4}>
                                                            <div className={Style.title}>{item.name}</div>
                                                            <div className={Style.detail}>De {moment(item.execution_date).format('DD-MMM-YYYY hh:mm A')} a {moment(item.expiration_date).format('DD-MMM-YYYY hh:mm A')}</div>
                                                            <div className={Style.detail}>Campa&ntilde;a {item.test ? 'de prueba' : 'oficial'} sobre {item.campaign_name} - {item.mails_sent + item.failed_emails + item.pending_emails} Destinatario(s) </div>
                                                        </Grid>
                                                        <Grid item xs={typeElements === 'Canceladas' ? 6 : 4}>
                                                            {typeElements === 'Canceladas' ?
                                                                <></>
                                                                :
                                                                <>
                                                                    <div className={Style.detail}>{item.mails_sent} Enviados</div>
                                                                    <div className={Style.detail}>{item.failed_emails} Fallidos</div>
                                                                </>
                                                            }
                                                            <div className={Style.detail}>{item.pending_emails} Pendientes</div>
                                                        </Grid>
                                                        {typeElements !== 'Canceladas' ?
                                                            <Grid item xs={2}>
                                                                <div className={Style.detail}>Ejecuci&oacute;n {item.state}</div>
                                                            </Grid>
                                                            : <></>}
                                                        {typeElements === 'Programadas' ?
                                                            <Grid item xs={2} className={Style.options}>
                                                                <Tooltip title="Editar campa&ntilde;a" placement="left" arrow>
                                                                    <IconButton onClick={() => update(item)}>
                                                                        <Edit />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Cancelar campa&ntilde;a" placement="left" arrow>
                                                                    <IconButton onClick={() => confirmDelete(item)}>
                                                                        <Close />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                            : <></>}
                                                        {typeElements === 'Ejecutadas' ?
                                                            <Grid item xs={2} className={Style.options}>
                                                                <Tooltip title="Descargar resultados en excel" placement="left" arrow>
                                                                    <IconButton onClick={() => download(item)} className={downloading && element.id === item.id ? Style.downloading : ''}>
                                                                        <ArrowDownward />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                            : <></>}
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                            <br />
                                        </div>
                                    ))
                                    : <NoDataToDisplay />
                            }
                        </Container>
                        <br /><br />
                        <Tooltip title="Programar nueva campa&ntilde;a" placement="left" arrow>
                            <Fab onClick={register} color="secondary">
                                <Add fontSize="large" />
                            </Fab>
                        </Tooltip>
                        {registering ? <Register element={element} getElements={getElements} typeElements={typeElements} hideModal={hideModal} /> : <></>}
                        {deleting ?
                            <Modal
                                title="Confirmaci&oacute;n"
                                content="¿ Deseas realmente continuar con la cancelaci&oacute;n ?"
                                secondaryActionName="Cancelar"
                                primaryActionName="Continuar"
                                secondaryAction={hideModal}
                                primaryAction={del}
                                primaryActionDisabled={false}
                                processing={processing} />
                            : <></>}
                    </>
                    : <></>
            }
        </Authenticated>
    );
}

export default PhishingCampaignExecution;