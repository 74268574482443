import { api } from '../../../Config';
import Style from './Style.module.css';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import { useState, useEffect } from 'react';
import { TextField, Stepper, Step, StepLabel, StepContent, Card, CardContent, Grid, Checkbox } from '@material-ui/core';
import Modal from '../../reusables/modal/Modal';
import Loading from '../../reusables/loading/Loading';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';

import {Button, ButtonGroup, InputLabel} from '@material-ui/core';
import {Add, Remove, VerifiedUser} from '@material-ui/icons';

import CSRFToken from '../../reusables/CSRFToken/CSRFToken';

function RegisterCampaign(props) {
    const [activeStep, setActiveStep] = useState(0);
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [form, setForm] = useState({
        client: localStorage.getItem('clientId'),
        name: '',
        addressee: localStorage.getItem('client_email') || '',
        execution_date: moment(new Date()).format('YYYY-MM-DDTHH:mm'),

        smtp_server: '',
        smtp_port: ''
    });

    const [resend, setResend] = useState(false);

    useEffect(() => {
        getFiles()
        if(props.campaign !== ''){
            setForm({
                client: props.campaign.client,
                name: props.campaign.name,
                addressee: props.campaign.addressee,
                execution_date: moment(props.campaign.execution_date).format('YYYY-MM-DDTHH:mm'),

                smtp_server: props.campaign.smtp_server || "",
                smtp_port: props.campaign.smtp_port || ""
            })
        }
    }, []);

    function getFiles(){
        fetch(api.urlFiles, {
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setFiles(data)
                    if(props.campaign !== ''){
                        for(let index in props.campaign.files){
                            setSelectedFiles(oldSelectedFiles => [...oldSelectedFiles, props.campaign.files[index].id]);
                        }
                    } else {
                        for(let index in data){
                            setSelectedFiles(oldSelectedFiles => [...oldSelectedFiles, data[index].id]);
                        }
                    }
                })
            } else if (data.status === 401) {
                RefreshToken()
                getFiles()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }

    function handleCheckChange(file){
        if (selectedFiles.includes(file)) {
            setSelectedFiles(
                selectedFiles.filter(function(element){
                    return element !== file
                })
            )
        } else {
            setSelectedFiles(oldSelectedFiles => [...oldSelectedFiles, file]);
        }
    }

    function seleccionarTodo(){
        let select = []
        files.forEach((file) => {
            select.push(file.id)
        }) 
        setSelectedFiles(select)     
    }

    function DeseleccionarTodo(){
        setSelectedFiles([])      
    }

    function register(){
        if (activeStep === 0) {
            if (form.name === '' || form.addressee === '' || form.execution_date === '') {
                msg.warning('Completa todos los campos para continuar')
            } else {
                setActiveStep(1)   
            }
        } else {
            if (selectedFiles.length === 0) {
                msg.warning('Debes seleccionar al menos un archivo')
            } else {
                setProcessing(true)
                let formData = new FormData();
                for(let key in form){
                    formData.append(key, form[key])
                }
                for(let key in selectedFiles){
                    formData.append('files', selectedFiles[key])
                }
               
                const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value;
                fetch(api.urlCampaigns+(props.campaign !== '' ? props.campaign.id+'/' : ''), {
                    method: (props.campaign !== '' ? 'put' : 'post'),
                    headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    'X-CSRFToken': csrftoken_pag},
                    credentials: 'include',
                    body: formData
                })
                .then((data) => {
                    if (data.status === 201 || data.status === 200){
                        if(props.typeCampaign === 'Programada'){
                            props.getCampaigns(props.typeCampaign)
                        }
                        props.hideModal()
                        setProcessing(false)
                        msg.success(props.campaign !== '' ? 'Actualización exitosa' : 'Registro exitoso')
                    } else if (data.status === 401) {
                        RefreshToken()
                        register()
                    } else {
                        setProcessing(false)
                        msg.error('Se ha producido un error')
                    }
                })
                //.catch(console.log)
            }
        }
    }

    function hideModal() {
        setProcessing(false)
        setResend(false)
    }

    function confirmResend() {
        setResend(true)
    }

    function ResendActivation(){
        if (selectedFiles.length === 0) {
            msg.warning('Debes seleccionar al menos un archivo')
        } else {
            setProcessing(true)

            fetch(api.urlResendEmailActivate, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'campaign': props.campaign.id
                })
            })
            .then((data) => {
                if (data.status === 200){
                    if(props.typeCampaign === 'Programada'){
                        props.getCampaigns(props.typeCampaign)
                    }
                    props.hideModal()
                    setProcessing(false)
                    msg.success('Se envió correo de activación con éxito')
                } else if (data.status === 401) {
                    RefreshToken()
                    register()
                } else {
    
                    setProcessing(false)
                    //msg.error('Se ha producido un error')
                    data.json().then((data) => {
                        msg.error(data.detail)
                    })
                    
                }
            })
            //.catch(console.log)
        }
    }
    return (
        <Modal
            title={props.campaign === '' ? 'Registro' : 'Actualización'}
            secondaryActionName="Cancelar"
            primaryActionName={activeStep === 0 ? 'Continuar' : props.campaign === '' ? 'Registrar' : 'Actualizar'}
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={false}
            processing={processing}
            maxWidth="md">

            <CSRFToken />
            <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                    <StepLabel onClick={() => setActiveStep(0)} style={{ cursor: 'pointer' }}>Configuraci&oacute;n de la campa&ntilde;a</StepLabel>
                    <StepContent>
                    <br/>
                    Campaña<Divider variant="inset"/>
                        <br/>
                        <TextField required value={form.name} onChange={handleChange} label="Nombre" name="name" variant="outlined" size="small" inputProps={{maxLength:100}} autoFocus={true}/>
                        <br/><br/>
                        <TextField required value={form.addressee} onChange={handleChange} label="Destinatario" name="addressee" variant="outlined" size="small" inputProps={{maxLength:100}}/>
                        <br/><br/>
                        <TextField required type="datetime-local" value={form.execution_date} onChange={handleChange} label="Fecha y hora" name="execution_date" variant="outlined" size="small"/>
                        <br/><br/>

                    SMTP (Open Relay)<Divider variant="inset"/>
                        <br/>
                        <TextField required value={form.smtp_server} onChange={handleChange} label="Servidor smtp" name="smtp_server" variant="outlined" size="small" inputProps={{maxLength:100}}/>
                        <br/><br/>
                        <TextField required value={form.smtp_port} onChange={handleChange} label="Puerto smtp" name="smtp_port" type="number" variant="outlined" size="small" inputProps={{maxLength:10}}/>
                        <br/>
                        <InputLabel>*Dejar en blanco para puertos (25, 587)*</InputLabel>
                        <br/><br/>

                        {props.campaign !== '' && props.campaign.status_cam === 'P' ?
                            <ButtonGroup size="small" aria-label="small outlined button group">
                                <Button onClick={confirmResend}><VerifiedUser/>Reenviar correo de activación</Button>
                            </ButtonGroup>
                        : <></>}
                        
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Archivos de verificaci&oacute;n</StepLabel>
                    <StepContent>
                        <br/>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Button onClick={seleccionarTodo}><Add/>Seleccionar todo</Button>
                            <Button onClick={DeseleccionarTodo}><Remove/> Deseleccionar todo</Button>
                        </ButtonGroup>
                        <br/>
                        {files.length > 0 ?
                            files.map((file, index) => (
                                <div key={index}>
                                    <Card className={Style.item}>
                                        <CardContent>
                                            <Grid container spacing={4}>
                                                <Grid item xs={1}>
                                                    <Checkbox checked={selectedFiles.includes(file.id)} onChange={() => handleCheckChange(file.id)}/>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={Style.title}>{file.name}</div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className={Style.detail}>{file.file_type_des}</div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className={Style.detail}>Formato {file.extension}</div>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <div className={Style.detail}>Riesgo {file.risk}</div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <br/>
                                </div>
                            ))
                        : <Loading variant="list" column={1} height={50}/>}
                    </StepContent>
                </Step>
            </Stepper>
            
            {resend ?
                <Modal
                    title="Confirmación"
                    content="¿Deseas realmente continuar reenviar el correo?"
                    secondaryActionName="Cancelar"
                    primaryActionName="Continuar"
                    secondaryAction={hideModal}
                    primaryAction={ResendActivation}
                    primaryActionDisabled={false}
                    processing={processing} />
                : <></>}
        </Modal>
        
    );
}

export default RegisterCampaign;