import { api } from '../../../Config';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import { useState, useEffect } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Modal from '../../reusables/modal/Modal';
import InputFile from '../../reusables/inputFile/InputFile';

import CSRFToken from '../../reusables/CSRFToken/CSRFToken';

function RegisterFile(props) {
    const [processing, setProcessing] = useState(false);
    const [risks, setRisks] = useState([]);
    const [file_types, setFile_types] = useState([]);
    const [form, setForm] = useState({
        name: '',
        description: '',
        risk: '',
        file: '',

        file_type: ''
    });

    useEffect(() => getRisks(), []);

    function getRisks(){
        fetch(api.urlFiles, {
            method: 'options',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setRisks(data.actions.POST.risk.choices)
                    setFile_types(data.actions.POST.file_type.choices);
                    if(props.file !== ''){
                        for(var option in data.actions.POST.risk.choices){
                            if(data.actions.POST.risk.choices[option].display_name === props.file.risk){
                                setForm({
                                    name: props.file.name,
                                    description: props.file.description,
                                    risk: data.actions.POST.risk.choices[option].value,
                                    file: props.file.file,

                                    file_type: props.file.file_type
                                })
                            }
                        }
                    }
                })
            } else if (data.status === 401) {
                RefreshToken()
                getRisks()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function handleChange(event){
        if (event.target.name === 'file'){
            setForm(prevState => ({...prevState, [event.target.name]: event.target.files[0]}));
        } else {
            setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
        }
    }

    function register(){
        setProcessing(true)
        
        var formData = new FormData();
        for(var key in form){
            if (props.file !== '' && form[key] === props.file.file) {
                formData.append(key, '')
            } else {
                formData.append(key, form[key])
            }
        }
        const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value;
        fetch(api.urlFiles+(props.file !== '' ? props.file.id+'/' : ''), {
            method: (props.file !== '' ? 'put' : 'post'),
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'X-CSRFToken': csrftoken_pag},
            credentials: 'include',
            body: formData
        })
        .then((data) => {
            if (data.status === 201 || data.status === 200){
                props.getFiles()
                props.hideModal()
                setProcessing(false)
                msg.success(props.file !== '' ? 'Actualización exitosa' : 'Registro exitoso')

            } else if (data.status === 401) {
                RefreshToken()
                register()
            } else {
                data.json().then((data) => {
                    if (data.name){
                        msg.error(data.name)
                    } else {
                        msg.error('Se ha producido un error')
                    }
                })
                setProcessing(false)
                //msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    return (
        <Modal
            title={props.file === '' ? 'Registro' : 'Actualización'}
            secondaryActionName="Cancelar"
            primaryActionName={props.file === '' ? 'Registrar' : 'Actualizar'}
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={form.name === '' || form.description === '' || form.risk === '' || form.file === ''}
            processing={processing}>

            <CSRFToken />
            <TextField value={form.name} onChange={handleChange} label="Nombre" name="name" variant="outlined" size="small" inputProps={{maxLength:100}} autoFocus={true}/>
            <br/><br/>
            <TextField value={form.description} onChange={handleChange} label="Descripci&oacute;n" name="description" variant="outlined" size="small" multiline inputProps={{maxLength:100}}/>
            <br/><br/>
            <FormControl variant="outlined" size="small">
                <InputLabel>Riesgo</InputLabel>
                <Select value={form.risk} name="risk" onChange={handleChange} label="Riesgo">
                    {risks.map((risk, index) => (
                        <MenuItem key={index} value={risk.value}>{risk.display_name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br/><br/>

            <FormControl variant="outlined" size="small">
                <InputLabel>Tipo clasificación</InputLabel>
                <Select value={form.file_type} name="file_type" onChange={handleChange} label="Tipo clasificación">
                    {file_types.map((file_type, index) => (
                        <MenuItem key={index} value={file_type.value}>{file_type.display_name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br/><br/>

            <InputFile id="file" name="file" handleChange={handleChange} fileName={form.file}/>
            <br/><br/>
        </Modal>
    );
}

export default RegisterFile;