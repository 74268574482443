import { api } from '../../../Config';
import Style from './Style.module.css';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import { useState, useEffect } from 'react';
//import { TextField } from '@material-ui/core';
import Modal from '../../reusables/modal/Modal';

//import { Container, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { TextField, Card, CardContent, Grid, Checkbox, Chip, Button, ButtonGroup } from '@material-ui/core';
import {Close, Check} from '@material-ui/icons';

import CSRFToken from '../../reusables/CSRFToken/CSRFToken';
import Divider from '@material-ui/core/Divider';

function Register(props) {
    const [processing, setProcessing] = useState(false);
    const [selectedPermiso, setSelectedPermiso] = useState([]);
    const [resend, setResend] = useState(false);

    const [form, setForm] = useState({
        uid: '',
        username: '',
        email: '',
        first_name: '',
        last_name: '',

        department: '',
        phone: '',
    });

    useEffect(() => {

        //getIndustries();

        if (props.element !== '') {
            setForm({
                uid: props.element.uid,
                activo: props.element.activo,
                username: props.element.username,
                email: props.element.email,
                first_name: props.element.first_name,
                last_name: props.element.last_name,

                department: props.element.department,
                phone: props.element.phone,
            })

            getPermisos(props.element)
        }
        
    }, []);


    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }

    function handleCheckChange(permiso){
        if (selectedPermiso.includes(permiso)) {
            setSelectedPermiso(
                selectedPermiso.filter(function(element){
                    return element !== permiso
                })
            )
        } else {
            setSelectedPermiso(oldSelectedPermiso => [...oldSelectedPermiso, permiso]);
        }
    }

    function getPermisos(e) {
        setProcessing(true)
        fetch(api.urlPermisoUser + e.uid+'/', {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        //console.log("data.permisos:", data.permisos)
                        // Extraer solo los nombres usando map()
                        setSelectedPermiso(data.permisos.map(permiso => permiso.cn))
                        setProcessing(false)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getPermisos(e)
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            //.catch(console.log)
    }

    function register(){
        setProcessing(true)
        const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value;
        //fetch(api.urlAddUser+(props.element !== '' ? props.element.id+'/' : ''), {
        fetch(api.urlMntUsers, {
            method: (props.element !== '' ? 'put' : 'post'),
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`, 'Content-Type': 'application/json'
            ,'X-CSRFToken': csrftoken_pag,},
            credentials: 'include',
            //body: JSON.stringify(form)
            body: JSON.stringify({
                ...form,
                permisos: selectedPermiso,
            }),
        })
        .then((data) => {

            if (data.status === 201 || data.status === 200){
                props.getElements()
                props.hideModal()
                setProcessing(false)
                msg.success(props.element !== '' ? 'Actualización exitosa' : 'Registro exitoso')
            } else if (data.status === 401) {
                RefreshToken()
                register()
            } else {
                /*data.json().then((data) => {
                    if (data.acronym){
                        msg.error(data.acronym)
                    }
                    if (data.email){
                        msg.error(data.email)
                    }
                })*/
                setProcessing(false)
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function confirmResend() {
        setResend(true)
    }
    function hideModal() {
        setProcessing(false)
        setResend(false)
    }

    function resend_activacion(){
        setProcessing(true)
        const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value;
        //fetch(api.urlAddUser+(props.element !== '' ? props.element.id+'/' : ''), {
        fetch(api.urlResendActivation, {
            method: 'post',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`, 'Content-Type': 'application/json'
            ,'X-CSRFToken': csrftoken_pag,},
            credentials: 'include',
            body: JSON.stringify({
                username: form.username,
                email: form.email,
            }),
        })
        .then((data) => {

            if (data.status === 200){
                props.getElements()
                props.hideModal()
                setProcessing(false)
                msg.success('Se envió activación')
            } else if (data.status === 401) {
                RefreshToken()
                register()
            } else {
                setProcessing(false)
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }


    return (
        <Modal
            title={props.element === '' ? 'Registro' : 'Actualización'}
            secondaryActionName="Cancelar"
            primaryActionName={props.element === '' ? 'Registrar' : 'Actualizar'}
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={form.username === '' || form.email === '' || form.first_name === '' || form.last_name === ''}
            processing={processing}
            maxWidth="sm">

            <CSRFToken />
            {props.element !== ''?  form.activo?<Check/>:<Chip color="secondary" icon={<Close/>}/> :<></> }
            <br/><br/>
            <TextField disabled={props.element !== ''} value={form.username} required onChange={handleChange} label="Nombre usuario" name="username" variant="outlined" size="small" inputProps={{maxLength:100}} autoFocus={true}/>
            <br/><br/>
            <TextField value={form.email} required onChange={handleChange} label="Correo" name="email" type="email" variant="outlined" size="small" inputProps={{maxLength:150}}/>
            <br/><br/>
            <TextField value={form.first_name} required onChange={handleChange} label="Nombres" name="first_name" variant="outlined" size="small" inputProps={{maxLength:150}}/>
            <br/><br/>
            <TextField value={form.last_name} required onChange={handleChange} label="Apellidos" name="last_name" variant="outlined" size="small" inputProps={{maxLength:150}}/>
            <br/><br/>

            <TextField value={form.department} onChange={handleChange} label="Departamento" name="department" variant="outlined" size="small" inputProps={{maxLength:170}}/>
            <br/><br/>            
            <TextField value={form.phone} onChange={handleChange} label="Teléfono" name="phone" type="number" variant="outlined" size="small" inputProps={{maxLength:20}}/>
            <br/><br/>

                {props.element !== '' && !form.activo ?
                    <ButtonGroup size="small" aria-label="small outlined button group">
                        <Button onClick={confirmResend}>Reenviar correo de activación</Button>
                    </ButtonGroup>
                : <></>}

            <br/><br/>

            Permisos<Divider variant="inset"/>
            <br/>
            {props.permisos.map((permiso, index) => (
                    <div key={index}>
                        <Card className={Style.item}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={1}>
                                        <Checkbox checked={selectedPermiso.includes(permiso.cn)} onChange={() => handleCheckChange(permiso.cn)}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className={Style.title}>{permiso.cn}</div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                ))
            }

            {resend ?
                <Modal
                    title="Confirmación"
                    content="¿Deseas realmente continuar reenviar el correo?"
                    secondaryActionName="Cancelar"
                    primaryActionName="Continuar"
                    secondaryAction={hideModal}
                    primaryAction={resend_activacion}
                    primaryActionDisabled={false}
                    processing={processing} />
                : <></>}


        </Modal>

        
    );
}


export default Register;