// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../DesignConstants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_container__3N3pT{\n    text-align: center;\n    bottom: 100px;\n    left: 0;\n    width: 100%;\n    position: fixed;\n}\n\n.Style_icon__3LHgR{\n    color: var(--primaryColor);\n    margin-bottom: 10px;\n}\n\n.Style_title__1lop6{\n    font-weight: bold;\n    font-size: 28px;\n    padding-bottom: 10px;\n}\n\n.Style_description__QXzcM{\n    color: var(--subtitleColor);\n}", "",{"version":3,"sources":["webpack://src/components/reusables/noDataToDisplay/Style.module.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,OAAO;IACP,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":["@import './../../../DesignConstants.css';\n\n.container{\n    text-align: center;\n    bottom: 100px;\n    left: 0;\n    width: 100%;\n    position: fixed;\n}\n\n.icon{\n    color: var(--primaryColor);\n    margin-bottom: 10px;\n}\n\n.title{\n    font-weight: bold;\n    font-size: 28px;\n    padding-bottom: 10px;\n}\n\n.description{\n    color: var(--subtitleColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Style_container__3N3pT",
	"icon": "Style_icon__3LHgR",
	"title": "Style_title__1lop6",
	"description": "Style_description__QXzcM"
};
export default ___CSS_LOADER_EXPORT___;
