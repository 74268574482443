import { api } from '../../Config';
import RefreshToken from '../../RefreshToken';
import * as msg from '../reusables/toast/Toast';
import { useEffect, useState } from 'react';
import { Container, FormControl, InputLabel, Select, MenuItem, IconButton } from '@material-ui/core';
import Authenticated from '../reusables/authenticated/Authenticated';
import PageTitle from '../reusables/pageTitle/PageTitle';
import Modal from '../reusables/modal/Modal';
import Loading from '../reusables/loading/Loading';

import {Build} from '@material-ui/icons';


import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { TextField } from '@material-ui/core';

import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

function RepHistoricoResumen() {
    const classes = useStyles();

    const [elements, setElements] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [registering, setRegistering] = useState(true);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState('');

    const [form, setForm] = useState({
        client: '',
        date_ini: '',
        date_fin: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
    });

    useEffect(() => getClients(), []);

    function getClients() {
        //setProcessing(true)
        fetch(api.urlClients, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        setClients(data)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getClients()
                } else {
                    msg.error('Se ha producido un error al obtener clientes')
                }
            })
            //.catch(console.log)
    }

    function hideModal(){
        setRegistering(false)
    }
    function parametros(){
        setRegistering(true)
    }

    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }
    function consultar(){
        if (form.client){
            let cliente = clients.find(p => p.id === form.client);
            setClient(cliente);
        }
        setRegistering(false)
        getElements();
    }
    function getElements(){
        setProcessing(true)
        fetch(api.urlRepHistoricoResumen+ '?clientid=' + form.client + '&date_ini=' + form.date_ini+ '&date_fin=' + form.date_fin, {
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setElements(data)
                    setProcessing(false)
                })
            } else if (data.status === 401) {
                RefreshToken()
                getElements()
            } else {
                setProcessing(false)
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    return (
        <Authenticated>
            <br/><br/><br/>
            <Container maxWidth="lg">
                <PageTitle primary="Reporte histórico resumido" secondary="los campañas realizadas por el cliente y los resultados obtenidos"/>
                <br/>

                <IconButton onClick={() => parametros()}><Build/>  Cliente: {client.name}</IconButton>

                {processing?
                    <Loading variant="list" column={1} height={50}/>
                :
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>Campaña</StyledTableCell>
                            <StyledTableCell align="right">Destinatario</StyledTableCell>
                            <StyledTableCell align="right">Ejecución</StyledTableCell>
                            <StyledTableCell align="right">Activo</StyledTableCell>
                            <StyledTableCell align="right">Estado prueba</StyledTableCell>

                            <StyledTableCell align="right">Obligatorio recibido</StyledTableCell>
                            <StyledTableCell align="right">Obligatorio bloqueado</StyledTableCell>
                            <StyledTableCell align="right">Otras extensiones recibido</StyledTableCell>
                            <StyledTableCell align="right">Otras extensiones bloqueado</StyledTableCell>
                            <StyledTableCell align="right">TOTAL</StyledTableCell>

                            <StyledTableCell align="right">Configurado</StyledTableCell>
                            <StyledTableCell align="right">No configurado</StyledTableCell>
                            <StyledTableCell align="right">TOTAL</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {elements.map((row) => (
                            <StyledTableRow
                            key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.addressee}</TableCell>
                            <TableCell align="right">{row.tiporeg == 'E'?moment(row.execution_date).format('DD/MM/YYYY HH:mm'):''}</TableCell>
                            <TableCell align="right">{row.status_cam_des}</TableCell>
                            <TableCell align="right">{row.status}</TableCell>
                            <TableCell align="right">{row.mails_sent_obliga}</TableCell>
                            <TableCell align="right">{row.failed_emails_obliga}</TableCell>
                            <TableCell align="right">{row.mails_sent_otras}</TableCell>
                            <TableCell align="right">{row.failed_emails_otras}</TableCell>
                            <TableCell align="right">{row.total_archivo}</TableCell>

                            <TableCell align="right">{row.configurado}</TableCell>
                            <TableCell align="right">{row.no_configurado}</TableCell>
                            <TableCell align="right">{row.total_seguridad}</TableCell>
                                         
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    }
            </Container>

            {registering ?
            <Modal
                title='Parámetros'
                secondaryActionName="Cancelar"
                primaryActionName='Aceptar'
                secondaryAction={hideModal}
                primaryAction={consultar}
                primaryActionDisabled={form.client === '' || form.date_ini === '' || form.date_fin === '' }
                processing={processing}
                maxWidth="sm">

                <br/>
                <Container maxWidth="lg">
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Cliente</InputLabel>
                        <Select required value={form.client} name="client" onChange={handleChange} label="Cliente">
                            
                            {clients.map((element, index) => (
                                <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Container>
                <br/>

                <br/>
                <InputLabel>Fecha inicio</InputLabel>
                <TextField required type="datetime-local" value={form.date_ini} onChange={handleChange} name="date_ini" variant="outlined" size="small"/>
                <br/><br/>
                <InputLabel>Fecha fin</InputLabel>
                <TextField required type="datetime-local" value={form.date_fin} onChange={handleChange} name="date_fin" variant="outlined" size="small"/>
                <br/><br/>

            </Modal>
            : <></>}
        </Authenticated>
      );
};

export default RepHistoricoResumen;