import { api } from '../../Config';
import RefreshToken from '../../RefreshToken';
import * as msg from '../reusables/toast/Toast';
import Style from './Style.module.css';
import { useEffect, useState } from 'react';
import { Container, Card, CardContent, Grid, IconButton, Tooltip, Fab, Chip } from '@material-ui/core';
import {Edit, Close, Add, Check, DoneOutline} from '@material-ui/icons';
import Authenticated from '../reusables/authenticated/Authenticated';
import PageTitle from '../reusables/pageTitle/PageTitle';
import Modal from '../reusables/modal/Modal';
import Register from './register/Register';
import Loading from '../reusables/loading/Loading';
import NoDataToDisplay from '../reusables/noDataToDisplay/NoDataToDisplay';

function UserManagement() {
    const [element, setElement] = useState('');
    const [elements, setElements] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [alta, setAlta] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [processing, setProcessing] = useState(true);

    const [permisos, setPermisos] = useState([]);

    useEffect(() => getElements(), []);


    function getElements(){
        setProcessing(true)
        fetch(api.urlUsers, {
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    //console.log("data.usuarios:", data.usuarios)
                    setElements(data.usuarios)
                    setPermisos(data.permisos)
                    setProcessing(false)
                })
            } else if (data.status === 401) {
                RefreshToken()
                getElements()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function hideModal(){
        setRegistering(false)
        setDeleting(false)
        setAlta(false)
    }

    function confirmDel(e){
        setDeleting(true)
        setElement(e)
    }
    function confirmAlta(e){
        setAlta(true)
        setElement(e)
    }

    function del(altabaja){
        setProcessing(true)
        fetch(api.urlEstadoUser, {
            method: 'post',
            //headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`},
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({
                'uid': element.uid,
                'estado': altabaja
                //'estado': 'B'
            })
        })
        .then((data) => {
            if (data.status === 200){
                setDeleting(false)
                setAlta(false)
                setProcessing(false)
                getElements()
                msg.success('operación exitosa')
            } else if (data.status === 401) {
                RefreshToken()
                del()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function update(e){
        setRegistering(true)
        setElement(e)
    }

    function register(){
        setRegistering(true)
        setElement('')
    }

    return (
        <Authenticated>
            <br/><br/><br/>
            <Container maxWidth="lg">
                <PageTitle primary="Gestión de usuarios" secondary="Gestión de usuarios para las pruebas de campaña"/>
                <br/>

                {processing && !registering && !deleting ?
                    <Loading variant="list" column={1} height={50}/>
                :
                    elements.length > 0 ?
                        elements.map((item, index) => (
                            <div key={index}>
                                <Card className={Style.item}>
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={4}>
                                                <div className={Style.title}>{item.username}</div>
                                                <div className={Style.detail}>{item.activo?<Check/>:<Chip color="secondary" icon={<Close />}/> } {item.first_name} {item.last_name}</div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className={Style.detail}>Departamento</div>
                                                <div className={Style.title}>{item.department}</div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className={Style.detail}>Correo: {item.email}</div>
                                                <div className={Style.detail}>Teléfono: {item.phone}</div>
                                            </Grid>
                                            <Grid item xs={2} className={Style.options}>
                                                <Tooltip title="Editar" placement="left" arrow>
                                                    <IconButton onClick={() => update(item)}>
                                                        <Edit/>
                                                    </IconButton>
                                                </Tooltip>
                                                {item.activo?
                                                <Tooltip title="De baja" placement="left" arrow>
                                                    <IconButton onClick={() => confirmDel(item)}>
                                                        <Close/>
                                                    </IconButton>
                                                </Tooltip>
                                                :
                                                <Tooltip title="De alta" placement="left" arrow>
                                                    <IconButton onClick={() => confirmAlta(item)}>
                                                        <DoneOutline/>
                                                    </IconButton>
                                                </Tooltip>
                                                }
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <br/>
                            </div>
                        ))
                    : <NoDataToDisplay/>
                }
            </Container>
            <br/><br/>
            <Tooltip title="Nuevo cliente" placement="left" arrow>
                <Fab onClick={register} color="secondary">
                    <Add fontSize="large" />
                </Fab>
            </Tooltip>
            {registering ? <Register element={element} permisos={permisos} getElements={getElements} hideModal={hideModal}/> : <></>}
            {deleting ?
                <Modal
                    title="Confirmación"
                    content="¿Desea dar de baja al usuario?"
                    secondaryActionName="Cancelar"
                    primaryActionName="Continuar"
                    secondaryAction={hideModal}
                    primaryAction={() => del('B')}
                    primaryActionDisabled={false}
                    processing={processing}/>
            : <></>}
            {alta ?
                <Modal
                    title="Confirmación"
                    content="¿Desea dar de alta al usuario?"
                    secondaryActionName="Cancelar"
                    primaryActionName="Continuar"
                    secondaryAction={hideModal}
                    primaryAction={() => del('A')}
                    primaryActionDisabled={false}
                    processing={processing}/>
            : <></>}
        </Authenticated>
    );
}

export default UserManagement;