// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_item__hMpLf{\n    box-shadow: 0px 0px 10px #ccc6 !important;\n    transition: 0.3s !important;\n}\n\n.Style_item__hMpLf:hover{\n    opacity: 1 !important;\n    box-shadow: 0px 2px 40px #ccc6 !important;\n}\n\n.Style_item__hMpLf>div{\n    padding: 5px 16px !important;\n}\n\n.Style_item__hMpLf>div>div{\n    display: flex;\n    align-items: center;\n    text-align: left;\n}\n\n.Style_item__hMpLf>div>div>div>.Style_title__2bOVr{\n    font-weight: bold;\n}\n\n.Style_item__hMpLf>div>div>div>.Style_detail__uoCpN{\n    font-size: 12px;\n    color: gray;\n}\n\n.Style_options__2u1Eg{\n    text-align: right;\n}", "",{"version":3,"sources":["webpack://src/components/IndustryManagement/Style.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".item{\n    box-shadow: 0px 0px 10px #ccc6 !important;\n    transition: 0.3s !important;\n}\n\n.item:hover{\n    opacity: 1 !important;\n    box-shadow: 0px 2px 40px #ccc6 !important;\n}\n\n.item>div{\n    padding: 5px 16px !important;\n}\n\n.item>div>div{\n    display: flex;\n    align-items: center;\n    text-align: left;\n}\n\n.item>div>div>div>.title{\n    font-weight: bold;\n}\n\n.item>div>div>div>.detail{\n    font-size: 12px;\n    color: gray;\n}\n\n.options{\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "Style_item__hMpLf",
	"title": "Style_title__2bOVr",
	"detail": "Style_detail__uoCpN",
	"options": "Style_options__2u1Eg"
};
export default ___CSS_LOADER_EXPORT___;
