import { api } from '../../../../Config';
import RefreshToken from '../../../../RefreshToken';
import * as msg from '../../../reusables/toast/Toast';
import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Modal from '../../../reusables/modal/Modal';

function Register(props) {
    const [processing, setProcessing] = useState(false);
    const [form, setForm] = useState({
        campaign: props.campaign,
        observation: ''
    });
  

    useEffect(() => {
        if (props.element !== '') {
            setForm({
                campaign: props.campaign,
                observation: props.element.observation || '',
            })
        }
    }, []);


    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }

    function register(){
        setProcessing(true)
        fetch(api.urlImmediateActionsCampaign+(props.element !== '' ? props.element.id+'/' : ''), {
            method: (props.element !== '' ? 'put' : 'post'),
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(form)
        })
        .then((data) => {
            if (data.status === 201 || data.status === 200){
                props.getElements()
                props.hideModal()
                setProcessing(false)
                msg.success(props.element !== '' ? 'Actualización exitosa' : 'Registro exitoso')
            } else if (data.status === 401) {
                RefreshToken()
                register()
            } else {
                msg.error('Se ha producido un error')
                setProcessing(false)
            }
        })
        //.catch(console.log)
    }
    return (
        <Modal
            title={props.element === '' ? 'Registro' : 'Actualización'}
            secondaryActionName="Cancelar"
            primaryActionName={props.element === '' ? 'Registrar' : 'Actualizar'}
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={form.observation === '' }
            processing={processing}
            maxWidth="md">

            {/*<CSRFToken />*/}
            <TextField required value={form.observation} onChange={handleChange} label="Acción inmediata" name="observation" variant="outlined" size="small" inputProps={{maxLength:500}} autoFocus={true}/>
            <br/><br/>

        </Modal>
    );
}
export default Register;