// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_root__22M7_{\n    background: #f6f6f6;\n    height: calc(100vh);\n    display: flex;\n    align-items: center;\n    padding: 0 17px;\n}\n\n.Style_content__2DCvl{\n    background: white;\n    padding: 40px 20px;\n    border-radius: 8px;\n    width: 100%;\n    max-width: 700px;\n    margin: 0 auto;\n    font-size: 24px;\n    text-align: center;\n}\n\n.Style_titulo__5RzaZ{\n    color: #ea2129;\n    font-weight: bold;\n}\n\n.Style_descripcion__c_7NZ{\n    color: #818286;\n}\n\n.Style_img__1MkL7{\n    display: initial;\n    width: 100%;\n    max-width: 300px;\n}", "",{"version":3,"sources":["webpack://src/components/notAuthorized/Style.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".root{\n    background: #f6f6f6;\n    height: calc(100vh);\n    display: flex;\n    align-items: center;\n    padding: 0 17px;\n}\n\n.content{\n    background: white;\n    padding: 40px 20px;\n    border-radius: 8px;\n    width: 100%;\n    max-width: 700px;\n    margin: 0 auto;\n    font-size: 24px;\n    text-align: center;\n}\n\n.titulo{\n    color: #ea2129;\n    font-weight: bold;\n}\n\n.descripcion{\n    color: #818286;\n}\n\n.img{\n    display: initial;\n    width: 100%;\n    max-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Style_root__22M7_",
	"content": "Style_content__2DCvl",
	"titulo": "Style_titulo__5RzaZ",
	"descripcion": "Style_descripcion__c_7NZ",
	"img": "Style_img__1MkL7"
};
export default ___CSS_LOADER_EXPORT___;
