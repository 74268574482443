// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_container__3g3fc{\n    display: flex;\n    height: 100vh;\n    width: 100vw;\n    background-color: #fafafb;\n}\n\n.Style_container__3g3fc>div{\n    max-width: 450px;\n    width: 100%;\n    margin: auto;\n    text-align: right;\n}\n\n.Style_container__3g3fc>div>img{\n    width: 120px;\n}\n\n.Style_paper__3yEyr{\n    text-align: left;\n    padding: 30px;\n    border-radius: 5px;\n    box-shadow: 0px 2px 30px #ccc6 !important;\n}\n\n.Style_title__3_Pol{\n    font-weight: bold;\n    font-size: 24px;\n    margin-bottom: 20px;\n}\n\n.Style_detail__3wNaj{\n    opacity: 0.5;\n}\n\n.Style_copyright__Mojsh{\n    text-align: center;\n    opacity: 0.3;\n    font-size: 12px;\n}", "",{"version":3,"sources":["webpack://src/components/reusables/htmlRenderer/Style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".container{\n    display: flex;\n    height: 100vh;\n    width: 100vw;\n    background-color: #fafafb;\n}\n\n.container>div{\n    max-width: 450px;\n    width: 100%;\n    margin: auto;\n    text-align: right;\n}\n\n.container>div>img{\n    width: 120px;\n}\n\n.paper{\n    text-align: left;\n    padding: 30px;\n    border-radius: 5px;\n    box-shadow: 0px 2px 30px #ccc6 !important;\n}\n\n.title{\n    font-weight: bold;\n    font-size: 24px;\n    margin-bottom: 20px;\n}\n\n.detail{\n    opacity: 0.5;\n}\n\n.copyright{\n    text-align: center;\n    opacity: 0.3;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Style_container__3g3fc",
	"paper": "Style_paper__3yEyr",
	"title": "Style_title__3_Pol",
	"detail": "Style_detail__3wNaj",
	"copyright": "Style_copyright__Mojsh"
};
export default ___CSS_LOADER_EXPORT___;
