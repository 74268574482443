import Style from './Style.module.css';
import { Link } from "react-router-dom";
import {Tab, IconButton, Tooltip} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

function BackButton(props) {
    return (
        <Link to={props.backUrl}>
            <Tooltip title="Regresa a la p&aacute;gina anterior" placement="right" arrow>
                {props.variant === 'tab' ?
                    <Tab icon={<KeyboardArrowLeft fontSize="large"/>} className={Style.btnTab}/>
                : 
                    <IconButton className={Style.btnTitle}>
                        <KeyboardArrowLeft fontSize="large"/>
                    </IconButton>
                }
            </Tooltip>
        </Link>
    );
}

export default BackButton;