import Style from './Style.module.css';
import Zoom from '@material-ui/core/Zoom';

function NotAuthorized() {
    return (
        <Zoom in={true}>
            <div className={Style.root}>
                <div className={Style.content}>
                    <img src="img/notAuthorized.png" className={Style.img} alt="No tienes autorizaci&oacute;n"/>
                    <br/><br/>
                    <div className={Style.titulo}>Lo sentimos</div>
                    <div className={Style.descripcion}>No tienes autorizaci&oacute;n para acceder a esta p&aacute;gina</div>
                </div>
            </div>
        </Zoom>
    );
}

export default NotAuthorized;