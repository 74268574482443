import Style from './Style.module.css';
import {Slide} from '@material-ui/core';
import {ViewList} from '@material-ui/icons';

function NoDataToDisplay() {
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <div className={Style.container}>
                <ViewList className={Style.icon} style={{ fontSize: 60 }}/>
                <div className={Style.title}>No hay registros</div>
                <div className={Style.description}>Cuando los agregues, aparecer&aacute;n en esta secci&oacute;n</div>
            </div>
        </Slide>
    );
}

export default NoDataToDisplay;