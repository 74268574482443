import { api } from '../../../Config';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import { useState, useEffect } from 'react';
import { TextField, Grid } from '@material-ui/core';
import Modal from '../../reusables/modal/Modal';

function Help(){
    return (
        <div>
            <br/>
            <div>VARIABLES DISPONIBLES EN EL HTML</div>
            <br/>
            <Grid container>
                <Grid item xs={6}>Nombre</Grid>
                <Grid item xs={6}>&#123;&#123;first_name&#125;&#125;</Grid>
                <Grid item xs={6}>Apellido</Grid>
                <Grid item xs={6}>&#123;&#123;last_name&#125;&#125;</Grid>
                <Grid item xs={6}>Correo electr&oacute;nico</Grid>
                <Grid item xs={6}>&#123;&#123;email&#125;&#125;</Grid>
                <Grid item xs={6}>Puesto</Grid>
                <Grid item xs={6}>&#123;&#123;job&#125;&#125;</Grid>
                <Grid item xs={6}>Criticidad del puesto</Grid>
                <Grid item xs={6}>&#123;&#123;job_criticality&#125;&#125;</Grid>
                <Grid item xs={6}>Edad</Grid>
                <Grid item xs={6}>&#123;&#123;age&#125;&#125;</Grid>
                <Grid item xs={12}><br/></Grid>
                <Grid item xs={12}>Landing page</Grid>
                <Grid item xs={12}>&#123;&#123;url_landing_page&#125;&#125;/name-of-mapped-action</Grid>
                <Grid item xs={12}><br/></Grid>
                <Grid item xs={12}>Awareness page</Grid>
                <Grid item xs={12}>&#123;&#123;url_awareness_page&#125;&#125;/name-of-mapped-action</Grid>
            </Grid>
            <br/>
            <div>ESTRUCTURA M&Iacute;NIMA DE HTML</div>
            <br/>
            &#60;html&#62;
            <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#60;body&#62;&#60;/body&#62;
            <br/>
            &#60;/html&#62;
            <br/>
            <br/>
        </div>
    );
}

function Register(props) {
    const [processing, setProcessing] = useState(false);
    const [form, setForm] = useState({
        name: '',
        subject: '',
        email_HTML: '',
        landing_page_HTML: '',
        awareness_HTML: ''
    });

    useEffect(() => {
        if (props.element !== '') {
            setForm({
                name: props.element.name,
                subject: props.element.subject,
                email_HTML: props.element.email_HTML,
                landing_page_HTML: props.element.landing_page_HTML,
                awareness_HTML: props.element.awareness_HTML
            })
        }
    }, []);

    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }

    function register(){
        setProcessing(true)
        fetch(api.urlPhishingCampaign+(props.element !== '' ? props.element.id+'/' : ''), {
            method: (props.element !== '' ? 'put' : 'post'),
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(form)
        })
        .then((data) => {
            if (data.status === 201 || data.status === 200){
                props.getElements()
                props.hideModal()
                setProcessing(false)
                msg.success(props.element !== '' ? 'Actualización exitosa' : 'Registro exitoso')
            } else if (data.status === 400) {
                setProcessing(false)
                msg.warning('Existen errores en el HTML ingresado')
            } else if (data.status === 401) {
                RefreshToken()
                register()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        .catch(console.log)
    }

    return (
        <Modal
            title="Campaña de phishing"
            secondaryActionName="Cancelar"
            primaryActionName={props.element === '' ? 'Registrar' : 'Actualizar'}
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={form.name === '' || form.subject === '' || form.email_HTML === '' || form.landing_page_HTML === '' || form.awareness_HTML === ''}
            processing={processing}
            maxWidth="xs"
            help={<Help/>}>
            <TextField value={form.name} onChange={handleChange} label="Nombre" name="name" variant="outlined" size="small" inputProps={{maxLength:100}} autoFocus={true}/>
            <br/><br/>
            <TextField value={form.subject} onChange={handleChange} label="Asunto" name="subject" variant="outlined" size="small" inputProps={{maxLength:100}}/>
            <br/><br/>
            <TextField value={form.email_HTML} onChange={handleChange} label="HTML de correo electr&oacute;nico" name="email_HTML" variant="outlined" size="small" multiline/>
            <br/><br/>
            <TextField value={form.landing_page_HTML} onChange={handleChange} label="HTML de Landing Page" name="landing_page_HTML" variant="outlined" size="small" multiline/>
            <br/><br/>
            <TextField value={form.awareness_HTML} onChange={handleChange} label="HTML de p&aacute;gina de concientizaci&oacute;n" name="awareness_HTML" variant="outlined" size="small" multiline/>
            <br/><br/>
        </Modal>
    );
}

export default Register;