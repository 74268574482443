import { api } from '../../../Config';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Modal from '../../reusables/modal/Modal';

import Style from './Style.module.css';

import Divider from '@material-ui/core/Divider';

import { Card, CardContent, Grid } from '@material-ui/core';

import CSRFToken from '../../reusables/CSRFToken/CSRFToken';

function Register(props) {
    const [processing, setProcessing] = useState(false);
    const [clients, setClients] = useState([]);
    const [form, setForm] = useState({
        name: '',
        country: '',
        description: '',
        /*direction: '',
        phone: '',
        name_contact: '',
        phone_contact: '',*/
    });

    useEffect(() => {

        if (props.element !== '') {
            setClients(props.element.clients);

            setForm({
                name: props.element.name,
                country: props.element.country,
                description: props.element.description,
                /*direction: props.element.direction,
                phone: props.element.phone,
                name_contact: props.element.name_contact,
                phone_contact: props.element.phone_contact*/
            })
        }
    }, []);

    function handleChange(event){
        setForm(prevState => ({...prevState, [event.target.name]: event.target.value}));
    }

    function register(){
        setProcessing(true)
        const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value;
        fetch(api.urlIndustry+(props.element !== '' ? props.element.id+'/' : ''), {
            method: (props.element !== '' ? 'put' : 'post'),
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken_pag,
            },
            credentials: 'include',
            body: JSON.stringify(form)
        })
        .then((data) => {
            if (data.status === 201 || data.status === 200){
                props.getElements()
                props.hideModal()
                setProcessing(false)
                msg.success(props.element !== '' ? 'Actualización exitosa' : 'Registro exitoso')
            } else if (data.status === 401) {
                RefreshToken()
                register()
            } else {
                data.json().then((data) => {
                    if (data.name){
                        msg.error(data.name)
                    } else {
                        msg.error('Se ha producido un error')
                    }
                })
                //msg.error('Se ha producido un error')
                setProcessing(false)
            }
        })
        //.catch(console.log)
    }
    return (
        <Modal
            title={props.element === '' ? 'Registro' : 'Actualización'}
            secondaryActionName="Cancelar"
            primaryActionName={props.element === '' ? 'Registrar' : 'Actualizar'}
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={form.name === '' /*|| form.direction === '' || form.phone === ''*/ }
            processing={processing}
            maxWidth="sm">

            <CSRFToken />
            Industria<Divider variant="inset"/>
            <br/>
            <TextField required value={form.name} onChange={handleChange} label="Nombre" name="name" variant="outlined" size="small" inputProps={{maxLength:150}} autoFocus={true}/>
            <br/><br/>
            <TextField value={form.country} onChange={handleChange} label="País" name="country" variant="outlined" size="small" inputProps={{maxLength:50}}/>
            <br/><br/>
            <TextField value={form.description} onChange={handleChange} label="Descripción" name="description" variant="outlined" size="small" inputProps={{maxLength:200}}/>
            <br/><br/>

            {/*<TextField required value={form.direction} onChange={handleChange} label="Dirección" name="direction" variant="outlined" size="small" inputProps={{maxLength:200}}/>
            <br/><br/>
            <TextField required value={form.phone} onChange={handleChange} label="Teléfono" name="phone" type="number" variant="outlined" size="small" inputProps={{maxLength:20}}/>
            <br/><br/>
            
            Contacto<Divider variant="inset" />
            <br/>
            <TextField value={form.name_contact} onChange={handleChange} label="Nombre del contacto" name="name_contact" variant="outlined" size="small" inputProps={{maxLength:150}}/>
            <br/><br/>
            <TextField value={form.phone_contact} onChange={handleChange} label="Teléfono del contacto" name="phone_contact" type="number" variant="outlined" size="small" inputProps={{maxLength:20}}/>
            <br/><br/>*/}
            Clientes<Divider variant="inset" />

            {clients.map((client, index) => (
                    <div key={index}>
                        <Card className={Style.item}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={1}>
                                        <div className={Style.detail}>{client.acronym}</div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className={Style.title}>{client.name}</div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className={Style.detail}>{client.email}</div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <br/>
                    </div>
                ))
            }

        </Modal>
    );
}
export default Register;