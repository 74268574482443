// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../DesignConstants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_btnTab__1fGFY{\n    border-radius: 5px !important;\n    color: rgba(0, 0, 0, 0.54) !important;\n    opacity: 1 !important;\n    min-width: unset !important;\n    padding: 3px !important;\n    margin-right: 18px !important;\n    background-color: var(--bgSecondaryButton) !important;\n    transition: all 0.2s;\n}\n\n.Style_btnTitle__23p9f{\n    border-radius: 5px !important;\n    padding: 3px !important;\n    position: absolute !important;\n    background-color: var(--bgSecondaryButton) !important;\n}\n\n.Style_btnTab__1fGFY:hover,\n.Style_btnTitle__23p9f:hover{\n    background-color: var(--bgHoverSecondaryButton) !important;\n}", "",{"version":3,"sources":["webpack://src/components/reusables/backButton/Style.module.css"],"names":[],"mappings":"AAEA;IACI,6BAA6B;IAC7B,qCAAqC;IACrC,qBAAqB;IACrB,2BAA2B;IAC3B,uBAAuB;IACvB,6BAA6B;IAC7B,qDAAqD;IACrD,oBAAoB;AACxB;;AAEA;IACI,6BAA6B;IAC7B,uBAAuB;IACvB,6BAA6B;IAC7B,qDAAqD;AACzD;;AAEA;;IAEI,0DAA0D;AAC9D","sourcesContent":["@import '../../../DesignConstants.css';\n\n.btnTab{\n    border-radius: 5px !important;\n    color: rgba(0, 0, 0, 0.54) !important;\n    opacity: 1 !important;\n    min-width: unset !important;\n    padding: 3px !important;\n    margin-right: 18px !important;\n    background-color: var(--bgSecondaryButton) !important;\n    transition: all 0.2s;\n}\n\n.btnTitle{\n    border-radius: 5px !important;\n    padding: 3px !important;\n    position: absolute !important;\n    background-color: var(--bgSecondaryButton) !important;\n}\n\n.btnTab:hover,\n.btnTitle:hover{\n    background-color: var(--bgHoverSecondaryButton) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnTab": "Style_btnTab__1fGFY",
	"btnTitle": "Style_btnTitle__23p9f"
};
export default ___CSS_LOADER_EXPORT___;
