import { useState } from 'react';
import Style from './Style.module.css';
import {IconButton, Tooltip, TextField} from '@material-ui/core';
import {AttachFile, ArrowDownward} from '@material-ui/icons';

function InputFile(props) {
    const [fileName, setFileName] = useState('');

    function loadFile(event){
        setFileName(event.target.files[0].name)
        props.handleChange(event)
    }

    return (
        <>
            <TextField value={fileName !== '' ? fileName : props.fileName.substr(props.fileName.lastIndexOf("/")+1)} label={props.label} variant="outlined" size="small" InputProps={{readOnly: true}} className={Style.inputLabel}/>
            <input id={props.id} name={props.name} type="file" accept={props.accept} onChange={loadFile}/>
            {props.download ?
                <a href={props.fileName} target='_blank'>
                    <Tooltip title="Descargar archivo" placement="left" arrow>
                        <IconButton size="small" component="span" className={Style.btnInputFile}>
                            <ArrowDownward/>
                        </IconButton>
                    </Tooltip>
                </a>
            :
                <label htmlFor={props.id}>
                    <Tooltip title="Cargar archivo" placement="left" arrow>
                        <IconButton size="small" component="span" className={Style.btnInputFile}>
                            <AttachFile/>
                        </IconButton>
                    </Tooltip>
                </label>
            }
        </>
    );
}

export default InputFile;