import { api } from '../../../Config';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import Style from './Style.module.css';
import { useState, useEffect } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Grid } from '@material-ui/core';
import Modal from '../../reusables/modal/Modal';
import InputFile from '../../reusables/inputFile/InputFile';
import moment from 'moment';

function Help() {
    return (
        <div className={Style.help}>
            <br />
            CRITICIDAD DE USUARIOS
            <br /><br />
            <Grid container>
                <Grid item xs={10}>Baja</Grid>
                <Grid item xs={2} align="right">1</Grid>
                <Grid item xs={10}>Media</Grid>
                <Grid item xs={2} align="right">2</Grid>
                <Grid item xs={10}>Alta</Grid>
                <Grid item xs={2} align="right">3</Grid>
                <Grid item xs={10}>Cr&iacute;tica</Grid>
                <Grid item xs={2} align="right">4</Grid>
            </Grid>
            <br />
        </div>
    );
}

function Register(props) {
    const [campaigns, setCampaigns] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [form, setForm] = useState({
        client: localStorage.getItem('clientId'),
        name: '',
        execution_date: moment(new Date).format('YYYY-MM-DDTHH:mm'),
        expiration_date: moment(new Date).format('YYYY-MM-DDTHH:mm'),
        email_from: '',
        test: false,
        campaign: '',
        file: ''
    });

    useEffect(() => {
        getCampaigns()
        if (props.element !== '') {
            setForm({
                client: props.element.client,
                name: props.element.name,
                execution_date: moment(props.element.execution_date).format('YYYY-MM-DDTHH:mm'),
                expiration_date: moment(props.element.expiration_date).format('YYYY-MM-DDTHH:mm'),
                email_from: props.element.email_from,
                test: props.element.test,
                campaign: props.element.campaign,
                file: 'Archivo cargado'
            })
        }
    }, []);

    function getCampaigns() {
        fetch(api.urlPhishingCampaign, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }
        })
            .then((data) => {
                if (data.status === 200) {
                    data.json().then((data) => {
                        setCampaigns(data)
                    })
                } else if (data.status === 401) {
                    RefreshToken()
                    getCampaigns()
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            .catch(console.log)
    }

    function handleChange(event) {
        if (event.target.name === 'file') {
            setForm(prevState => ({ ...prevState, [event.target.name]: event.target.files[0] }));
        } else if (event.target.name === 'test') {
            setForm(prevState => ({ ...prevState, [event.target.name]: event.target.checked }));
        } else {
            setForm(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
        }
    }

    function register() {
        if (form.expiration_date > form.execution_date) {
            setProcessing(true)
            var formData = new FormData();
            for (var key in form) {
                if (form[key] === 'Archivo cargado') {
                    formData.append(key, '')
                } else {
                    formData.append(key, form[key])
                }
            }
            fetch(api.urlPhishingCampaignExecution + (props.element !== '' ? props.element.id + '/' : ''), {
                method: (props.element !== '' ? 'put' : 'post'),
                headers: { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` },
                body: formData
            })
                .then((data) => {
                    if (data.status === 201 || data.status === 200) {
                        if (props.typeElements === 'Programadas') {
                            props.getElements(props.typeElements)
                        }
                        props.hideModal()
                        setProcessing(false)
                        msg.success(props.element !== '' ? 'Actualización exitosa' : 'Registro exitoso')
                    } else if (data.status === 401) {
                        RefreshToken()
                        register()

                    } else if (data.status === 400) {
                        msg.error('Formato de archivo incorrecto')
                        setProcessing(false)

                    } else {
                        msg.error('Se ha producido un error')
                    }
                })
                .catch(console.log)
        } else {
            msg.warning('La fecha de expiración y ejecución no son correctas')
        }
    }

    return (
        <Modal
            title="Campaña de phishing"
            secondaryActionName="Cancelar"
            primaryActionName={props.element === '' ? 'Registrar' : 'Actualizar'}
            secondaryAction={props.hideModal}
            primaryAction={register}
            primaryActionDisabled={form.name === '' || form.execution_date === '' || form.expiration_date === '' || form.email_from === '' || form.campaign === '' || form.file === ''}
            processing={processing}
            maxWidth="xs"
            help={<Help />}>
            <TextField value={form.name} onChange={handleChange} label="Nombre" name="name" variant="outlined" size="small" inputProps={{ maxLength: 100 }} autoFocus={true} />
            <br /><br />
            <TextField type="datetime-local" value={form.execution_date} onChange={handleChange} label="Fecha y hora de ejecuci&oacute;n" name="execution_date" variant="outlined" size="small" />
            <br /><br />
            <TextField type="datetime-local" value={form.expiration_date} onChange={handleChange} label="Fecha y hora de expiraci&oacute;n" name="expiration_date" variant="outlined" size="small" />
            <br /><br />
            <TextField value={form.email_from} onChange={handleChange} label="Correo electr&oacute;nico del remitente" name="email_from" variant="outlined" size="small" inputProps={{ maxLength: 100 }} />
            <br /><br />
            <FormControl variant="outlined" size="small">
                <InputLabel>Plantilla de campa&ntilde;a</InputLabel>
                <Select value={form.campaign} name="campaign" onChange={handleChange} label="Plantilla de campa&ntilde;a">
                    {campaigns.map((item, index) => (
                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br /><br />
            <InputFile id="file" name="file" label="CSV de usuarios en el alcance" accept=".CSV" handleChange={handleChange} fileName={form.file} />
            <a href="./formats/Usuarios.csv" target='_blank' className={Style.downloadFormat}>Descargar formato</a>
            <br /><br />
            <FormControlLabel control={<Switch name="test" checked={form.test} onChange={handleChange} />} label="Campa&ntilde;a de prueba" labelPlacement="start" />
            <br /><br />
        </Modal>
    );
}

export default Register;