// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../DesignConstants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_container__PhRv9{\n    padding-top: 30px;\n    padding-bottom: 10px !important;\n}\n\n.Style_primary__Pet_X{\n    font-weight: bold;\n    font-size: 16px;\n}\n\n.Style_secondary__1oKrT{\n    font-size: 11px;\n    color: var(--subtitleColor);\n}\n\na~span{\n    padding-left: 60px;\n}", "",{"version":3,"sources":["webpack://src/components/reusables/pageTitle/Style.module.css"],"names":[],"mappings":"AAEA;IACI,iBAAiB;IACjB,+BAA+B;AACnC;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["@import '../../../DesignConstants.css';\n\n.container{\n    padding-top: 30px;\n    padding-bottom: 10px !important;\n}\n\n.primary{\n    font-weight: bold;\n    font-size: 16px;\n}\n\n.secondary{\n    font-size: 11px;\n    color: var(--subtitleColor);\n}\n\na~span{\n    padding-left: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Style_container__PhRv9",
	"primary": "Style_primary__Pet_X",
	"secondary": "Style_secondary__1oKrT"
};
export default ___CSS_LOADER_EXPORT___;
